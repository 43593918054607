
import { useEffect, useState } from "react"
import { ArcLogo } from "../../../components/Assets"
import { CentralTableComponent } from "../../CentralStatsTable";
import { axios, } from "../../../store"

const Field = { attribute: 'dateRange', type: 'select' }

const initPriorityRows = [
    { priority: 'Critical', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'bgCritical' },
    { priority: 'High', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'bgHigh' },
    { priority: 'Medium', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'bgMedium' },
    { priority: 'Low', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'bgLow' },
    { priority: 'Minimal', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'bgMinimal' },
]

const initRowClassName = {
    'Critical': 'bgCritical',
    'High': 'bgHigh',
    'Medium': 'bgMedium',
    'Low': 'bgLow',
    'Minimal': 'bgMinimal',
}

export default function PriorityWiseCompliance(props) {
    const { user, statusList, departments, priorities, res } = props
    const [responseData, setResponseData] = useState({})
    const deptInitialRows = [...initPriorityRows]
    const PriorityColumns = [
        { label: '', attribute: 'priority', headerClassName: 'txt-left', isLabel: true, width: '7%' },
        { label: 'Total', attribute: 'Total', headerClassName: 'bgDDFFFF', width: '8%' },
        { label: 'Complete', attribute: 'Complete', headerClassName: 'published', width: '8%' },
        { label: 'InProgress', attribute: 'InProgress', headerClassName: 'orange-f77a50', width: '8%' },
        { label: 'Pending', attribute: 'Pending', headerClassName: 'cFE3333', width: '8%' },
        { label: 'Other', attribute: 'Other', headerClassName: 'c777', width: '8%' },
    ]
    const [tableData, setTableData] = useState([...deptInitialRows])
    const getDataFromServer = async (dateRange) => {
        let payload = {
            "date_range_type": "last_365_days",
            "start_date": "01-11-2023",
            "end_date": "20-11-2024"
        }
        if (dateRange && dateRange.id) {
            payload.date_range_type = dateRange.id
        }
        let res = await axios.post('/tasks/arc_central_dashboard', payload)
        setResponseData(res)
    }
    useEffect(() => {
        getDataFromServer()
    }, [])
    useEffect(() => {
        let tempTableData = getDataFromResponse(responseData, user, departments)
        setTableData([...tempTableData])
    }, [responseData])

    const tableID = 'arc-priority-table'
    return (
        <>
            <CentralTableComponent
                componentID={'arc_priority_wise_status'}
                componentStyle={{ minWidth: '50%', maxWidth: '55%' }}
                logoComponent={<ArcLogo className='logo' />}
                className=''
                title='Priority-wise Compliance Status'
                topBarField={Field}
                rows={tableData}
                Columns={PriorityColumns}
                tableField={Field}
                tableID={tableID}
                tableClassName='arc-stats-tbl'
                onDateChange={getDataFromServer}
                imageSrc={props.imageSrc}
                exportCompWidth={1100}
                rightPadding={145}
                TopHeaderBlack={true}
                isNotMaxHeight={true}
            />
        </>
    )
}

const getDataFromResponse = (res, user, departments) => {

    if (res.status_priority_list) {
        const brandname = user.ent_org.brandname;
        // const capitalizedBrandname = brandname.charAt(0).toUpperCase() + brandname.slice(1).toLowerCase();
        let totalRow = { priority: brandname, Total: 0, Complete: 0, InProgress: 0, Pending: 0, Other: 0, labelClassName: 'bgTotal' };
        let output = []
        const responseData = res.status_priority_list
        if (Object.keys(responseData).length > 0) {
            let priority_dict = {}
            Object.keys(responseData).map((status_key) => {
                Object.keys(responseData[status_key]).map(priority_key => {
                    if (!Boolean(priority_dict[priority_key])) {
                        priority_dict[priority_key] = {}
                    }
                    priority_dict[priority_key][status_key] = responseData[status_key][priority_key]
                })
            })
            // Merge Default with Medium
            if ('Default' in priority_dict) {
                if (!priority_dict['Medium']) {
                    priority_dict['Medium'] = {}
                }
                Object.keys(priority_dict['Default']).forEach(status_key => {
                    if (!(Boolean(priority_dict['Medium'][status_key]))) {
                        priority_dict['Medium'][status_key] = 0
                    }
                    priority_dict['Medium'][status_key] += priority_dict['Default'][status_key]
                })
            }

            let output_array = []
            let priorities = ['Critical', 'High', 'Medium', 'Low', 'Minimal']
            priorities.forEach(priority_key => {
                let row = { 'Total': 0, 'Complete': 0, 'InProgress': 0, 'Pending': 0, 'Other': 0 }
                if (priority_key in priority_dict) {
                    Object.keys(priority_dict[priority_key]).forEach(status_key => {
                        if (['Complete', 'InProgress', 'Pending'].includes(status_key)) {
                            row[status_key] = priority_dict[priority_key][status_key]
                        }
                        else {
                            row['Other'] = priority_dict[priority_key][status_key]
                        }
                        row['Total'] += priority_dict[priority_key][status_key]
                    })
                }
                row['labelClassName'] = initRowClassName[priority_key]
                row['priority'] = priority_key
                output_array.push(row)
            })

            for (let row of output_array) {
                Object.keys(row).forEach(key => {
                    if (key != 'priority' && key != 'labelClassName') {
                        totalRow[key] += row[key]
                    }
                })
            }

            output_array.unshift(totalRow)
            output = output_array.filter(row => {
                return Object.entries(row).some(([key, value]) => (key !== 'priority' && value !== 0));
            });

            output.forEach((row) => {
                Object.keys(row).forEach((columnKey) => {
                    if (row[columnKey] === 0 || row[columnKey] === '0') {
                        row[columnKey] = '-'
                    }
                })
            })
        }
        return output
    }
    else {
        return []
    }
}