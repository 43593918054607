import React, { useState } from 'react';
import { Select, Space } from 'antd';


const SimpleSelect = ({ onChange }) => {
    const [hideIcon, setHideIcon] = useState(false);
    const handleChange = (value) => {
        onChange(value)
        setHideIcon(true);
    };
    return (
        <Space wrap className='mb1'>
            <div className='exo2 f9' style={{ width: '70px' }}>Format:  <span style={{ color: 'red' }}>*</span></div>
            <Select
                placeholder="filetype"
                style={{
                    width: 100,
                }}
                suffixIcon={hideIcon ? null : undefined}
                className='custom-border'
                onChange={handleChange}
                options={[
                    {
                        value: 'png',
                        label: 'PNG',
                    },
                    {
                        value: 'pdf',
                        label: 'PDF',
                    },
                ]}
            />
        </Space>
    )

};
export default SimpleSelect;