import { CentralTable } from "./CentralTable";
import { CentralTableTopBar } from "./TableTopBar";
import { useSelector, useDispatch } from "react-redux";
import { getDateRangeEnums, docActions } from "../../store"
import { useEffect, useState } from "react";
import { ExportPage } from "./ExportPage";

export const CentralTableComponent = (props) => {
    const dispatch = useDispatch();
    const [tableHeight, setTableHeight] = useState(500)
    const [isOverflow, setIsOverFlow] = useState(false)
    
    useEffect(() => {
        dispatch(docActions.fetchDateRangeEnums());
    },[])
    useEffect(() => {
        const tableRowElement = document.querySelector('.table-row-1')
        const tableHeaderRow = document.querySelector('.table-column-row-1')
        if (tableRowElement && tableHeaderRow) {
            setTableHeight((tableRowElement.clientHeight) * 10 + tableHeaderRow.clientHeight)
        }
        //for handling shodows during overflow
        const tableElement = document.getElementById(props.tableID)
        if (tableElement) {
            let isOverflow = tableElement.scrollHeight > tableHeight
            setIsOverFlow(isOverflow)
        }
    }, [props.rows])

    let rows_to_display = 15
    if (props.rows.length > 15) {
        rows_to_display = 12
    }

    let arr = [0,]
    let i = rows_to_display
    if (Array.isArray(props.rows) && props.rows.length > rows_to_display) {

        while (arr.length < (Math.ceil(props.rows.length / rows_to_display))) {
            arr.push(i)
            i += rows_to_display
        }
    }

    const dateRangeEnumns = useSelector(getDateRangeEnums);
    useEffect(() => {
        if (dateRangeEnumns.length > 0) {
            let customDateRange = [
                { "id": "last_365_days", "label": "Last 365 Days" },
            ]
            for (let obj in dateRangeEnumns) {
                if (obj.id !== "last_365_days") {
                    customDateRange.push(obj)
                }
            }
            setDateRange(customDateRange[0])
        }
    }, [dateRangeEnumns])
    const [dateRange, setDateRange] = useState({id:'last_365_days', label:'Last 365 Days'})

    useEffect(() => {
        props.onDateChange && props.onDateChange(dateRange);
    }, [dateRange])


    const handleDateChange = (value) => {
        let dateRangeLabel = ""
        if (dateRangeEnumns) {
            let filteredDateRange = dateRangeEnumns.filter((option) => option.id === value)
            if (filteredDateRange && filteredDateRange.length > 0 && filteredDateRange[0].label) {
                dateRangeLabel = filteredDateRange[0].label
            }
        }
        setDateRange({ id:value, label:dateRangeLabel })
    };

    return <>

        <div id={props.componentID} className="oy-auto" style={{ marginBottom: '50px', marginLeft: '30px', ...props.componentStyle }}>
            <div >
                <div>
                    <CentralTableTopBar
                        logoComponent={props.logoComponent}
                        title={props.title}
                        rows={props.rows}
                        Columns={props.Columns}
                        Field={props.topBarField}
                        tableID={props.tableID}
                        componentId={props.componentID}
                        selectedDateId={dateRange.id}
                        selectedDateLabel={dateRange.label}
                        handleFieldChange={handleDateChange}
                        options={dateRangeEnumns}
                    />
                </div>
                <div  style={{ maxHeight: props.isNotMaxHeight?'':tableHeight, overflow: 'auto' }}>
                    <div className="inc-stats" style={{paddingBottom:'0px'}}>
                        <CentralTable
                            title={props.title}
                            rows={props.rows}
                            Columns={props.Columns}
                            Field={props.tableField}
                            tableID={props.tableID}
                            tableClassName={props.tableClassName}
                            TopHeaderBlack={props.TopHeaderBlack}
                            isReduceRowHeight={props.isReduceRowHeight}
                        />
                    </div>
                </div>
                <div className={` ${(!props.isNotMaxHeight) && isOverflow ? 'shadow-box' : ''}`} style={{height:'5px'}} ></div>

            </div>
        </div>
        {
            arr.map((i, index) => {
                let compWidth = props.exportCompWidth ? props.exportCompWidth : 1100
                let rightPadding = props.rightPadding ? props.rightPadding : 0
                const compHeight = '800px'
                const index_ = index + 1
                let Columns = []

                if (props.Columns.length > 1) {
                    props.Columns.forEach(column => {
                        
                        Columns.push({ ...column })
                    })
                    Columns.forEach((column) => {
                        if(column['cellClassName'] && column.cellClassName.includes('ellipses-text')){
                            column['cellClassName']+=' export-ellipsis-text'
                        }
                    })
                    if (!(Array.isArray(props.rows) && props.rows.length > 0)) {
                        let columnWidth = parseInt(compWidth / (Columns.length))
                        Columns.forEach((column) => {
                            column['width'] = columnWidth
                        })
                    }
                    if (index === 0 && (props.tableID !== 'auditor-table')) {
                        for (let i = 0; i < Columns.length; i++) {
                            Columns[i]['isHeaderCellBlack'] = true
                        }
                    }
                }
                return (
                    <ExportPage
                        componentID={props.componentID + '_export_' + index_.toString()}
                        imageSrc={props.imageSrc}
                        logoComponent={props.logoComponent}
                        index={index_}
                        totalPageCount={arr.length}
                        compHeight={compHeight}
                        compWidth={compWidth}
                        rightPadding={rightPadding}
                    >
                        <>
                            <div style={{ width: compWidth, }}>
                                <CentralTableTopBar
                                    title={props.title}
                                    Field={props.topBarField}
                                    tableWidth={compWidth- rightPadding}
                                    componentId={props.componentID + '_export'}
                                    isExport={true}
                                    selectedDateId={dateRange.id}
                                    selectedDateLabel={dateRange.label}
                                    handleFieldChange={handleDateChange}
                                    tableID={props.tableID}
                                />
                            </div>
                            <div className="inc-stats " style={{ width: compWidth-rightPadding, }}>
                                <CentralTable
                                    title={props.title}
                                    rows={props.rows.slice(i, i + rows_to_display)}
                                    Columns={Columns}
                                    Field={props.tableField}
                                    tableClassName={props.tableClassName}
                                    tableID={props.tableID}
                                    isExport={true}
                                />
                            </div>
                        </>
                    </ExportPage>
                )
            })
        }
    </>
}

