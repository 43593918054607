import "./saga";
import moment from "moment";
import { encode, decode } from "base-64";
export * from "./slice";
export * from "./endpoints";
const getDocState = (state) => state.document;

export const getDepartments = (state) => {
  const _state = getDocState(state);
  return _state.departments
}

export const getDocStatusList = (state) => {
  const _state = getDocState(state);
  return _state.docStatusList
}

export const getCurrentAPI = (state) => {
  const _state = getDocState(state);
  return _state.currentAPI
}
export const getDocStats = (state) => {
  const _state = getDocState(state);
  return _state.stats
}
export const getStatusStats = (state) => {
  const stats = getDocStats(state)
  return stats && stats.status_wise
}
export const getDeptStatusStats = (state) => {
  const stats = getDocStats(state)
  const list = [];
  let depts = getDepartments(state), statusList = getDocStatusList(state)
  if (stats && stats.department_status && Array.isArray(depts) && Array.isArray(statusList)) {
    let overall = { overall: 0, title: "Overall" }
    for (let i = 0; i < depts.length; i++) {
      const dept = depts[i];
      let row = { title: dept.label, overall: 0 }
      let deptVal = stats.department_status[dept.id]
      for (let j = 0; j < statusList.length; j++) {
        const status = statusList[j].id;
        if (status !== 'archived') {
          row[status] = deptVal ? (deptVal[status] || 0) : 0;
          row.overall += row[status]
          overall.overall += row[status]
          overall[status] = (overall[status] || 0) + row[status]
        }
      }
      list.push(row)
    }
    list.unshift(overall)
  }
  return list
}
export const getTypeStatusStats = (state) => {
  const stats = getDocStats(state)
  const list = [];
  // let types = getDocTypes(state), statusList = getDocStatusList(state)
  // if (stats && stats.type_status && Array.isArray(types) && Array.isArray(statusList)) {
    if (stats && stats.type_status ) {
      return stats.type_status
  //   let overall = { overall: 0, title: "Overall" }
  //   for (let i = 0; i < types.length; i++) {
  //     const type = types[i]
  //     let row = { title: type.label, overall: 0 }
  //     let typeVal = stats.type_status[type.id]
  //     for (let j = 0; j < statusList.length; j++) {
  //       const status = statusList[j].id
  //       if (status !== 'archived') {
  //         row[status] = typeVal ? (typeVal[status] || 0) : 0;
  //         row.overall += row[status]
  //         overall.overall += row[status]
  //         overall[status] = (overall[status] || 0) + row[status]
  //       }
  //     }
  //     list.push(row)
  //   }
  //   list.unshift(overall)
  // }
  // return list
}
}
export const encyptDataObject = (iData) => {
  let parsed = JSON.stringify(iData);
  parsed = encode(parsed);
  return parsed;
}
export const decryptDataObject = (iDataString) => {
  let decoded = decode(iDataString);
  decoded = JSON.parse(decoded);
  return decoded;
}
export const getDownloadStatus = (state) => {
  const _state = getDocState(state);
  return _state.downloadStatus
}
export const getShareStatus = (state) => {
  const _state = getDocState(state);
  return _state.shareStatus
}
export const getInvestigatorStatistics = (state) => {
  const _state = getDocState(state);
  return _state.statistics
}
export const getDateRangeEnums = (state) => {
  const _state = getDocState(state);
  return _state.dateRangeEnumns;
}
export const getSeverityTypes = (state) => {
  const _state = getDocState(state);
  return _state.severityTypes;
}
export const getSeverityTypeById = (severities, severity, state) => {
  let list = Array.isArray(severities) ? severities : getSeverityTypes(state);
  return list.find(({ id }) => (id === severity));
}
export const getStatusTypes = (state) => {
  const _state = getDocState(state);
  return _state.statusTypes;
}
export const getActiveGuides = (state) => {
  const _state = getDocState(state);
  return _state.activeGuides ;
}
export const getShowCentralGuide = (state) => {
  const _state = getDocState(state);
  return _state.showGuide
}