import { useEffect, useState } from "react";
import { VideoPlayer, ImageViewer } from "./Video-Image";

export const GuideSection = ({ sectionContent }) => {
    const [sectionContentInFormat, setSectionContentInFormat] = useState()
    const [showModal, setShowModal] = useState(false)

    const convertSectionContentInFormat = (sectionContent) => {
        let allContent = [];
        if (sectionContent.guide_stmts && sectionContent.guide_stmts.length > 0) {
            sectionContent.guide_stmts.forEach(stmt => {
                allContent.push({
                    id: stmt.id,
                    type: 'paragraph',
                    value: stmt.stmt,
                    attribute: `Paragraph ${stmt.order_no}`,
                    label: 'Paragraph',
                    order_no: stmt.order_no
                });
            });
        }
        if (sectionContent.youtube_links && sectionContent.youtube_links.length > 0) {
            sectionContent.youtube_links.forEach(link => {
                allContent.push({
                    id: link.id,
                    type: 'youtube_link',
                    value: link.link,
                    attribute: `Video Link ${link.order_no}`,
                    label: 'YouTube Link',
                    order_no: link.order_no
                });
            });
        }
        if (sectionContent.attachments && sectionContent.attachments.length > 0) {
            sectionContent.attachments.forEach(att => {
                allContent.push({
                    id: att.id,
                    type: 'image',
                    value: att.document_file_name,
                    attribute: `Image ${att.order_no}`,
                    label: 'Image',
                    url: att.evidence_url.startsWith('//') ? 'https:' + att.evidence_url : att.evidence_url,
                    order_no: att.order_no
                });
            });
        }
        allContent.sort((a, b) => a.order_no - b.order_no);
        setSectionContentInFormat(allContent)
    }
    useEffect(() => {
        convertSectionContentInFormat(sectionContent)
    }, [sectionContent])

    return (
        <div style={{ paddingBottom: '10px' }}>
            <h4 className="exo2 bold" >{sectionContent.title}</h4>
            {
                sectionContentInFormat &&
                sectionContentInFormat.map((item) => {
                    if (item.type === 'paragraph') {
                        return (
                            <div className='f9 col pl txt-justify' dangerouslySetInnerHTML={{ __html: item.value }} />
                        )
                    }
                    else if (item.type === 'image') {
                        return (<div
                            onClick={() => { setShowModal(!showModal)}}
                            style={{ marginTop: '20px', marginBottom: '20px' }}
                        >
                            <img src={item.url} alt={item.value} style={{ height: '250px', width: '500px' }} />
                            {showModal && <ImageViewer image_url={item.url} onClose={() => setShowModal(!showModal)} />}
                        </div>)
                    }
                    else {
                        return <VideoPlayer url={item.value} />
                    }
                })
            }
        </div>
    )
}