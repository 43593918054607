import { createSlice } from "@reduxjs/toolkit";
import { put } from "redux-saga/effects";
import SagaRegistryHelper from "./SagaRegistry";
import ReducerRegistryHelper from "./ReducerRegistry";
import moment from "moment";
import Random from "randomstring";
import {axios} from './axios'
const DATE_FORMAT = 'ddd, DD-MMM-YYYY';
const DATE_TIME_FORMAT = 'ddd, DD-MMM-YY HH:mm';
const EXPORT_DATE_FORMAT = 'YYMMDD'

const initialState = { notification: null, loading: false, fullLoading: false, progressTokens: [] , departments: [],}

const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    setAlert: (state, actions) => {
      state.notification = actions.payload
    },
    setLoading: (state, actions) => {
      state.loading = actions.payload
    },
    setFullLoading: (state, actions) => {
      state.fullLoading = actions.payload
    },
    startProgress: (state, actions) => {
      state.progressTokens = [actions.payload, ...state.progressTokens]
    },
    stopProgress: (state, actions) => {
      const progressTokens = [...state.progressTokens]
      let index  = progressTokens.indexOf(actions.payload);
      if (index > -1) {
        progressTokens.splice(index, 1)
      }
      state.progressTokens = [...progressTokens]
    },

    fetchDepartments: () => {},
    setDepartments: (state, action) => {
      state.departments = action.payload
    },
  }
})
const actions = commonSlice.actions;

export function* setAlertAndLoading(isLoading = null, alert = null, fullLoading = null) {
  if (alert !== null) {
    alert.severity = alert.success === false ? 'error' : 'success';
    yield put(actions.setAlert(alert));
  }
  if (isLoading !== null) {
    yield put(actions.setLoading(isLoading));
  }
  if (fullLoading !== null) {
    yield put(actions.setLoading(fullLoading));
  }
}

function* onSagaErrorCatch(error, errorMsg) {
  let alert;
  error = typeof error == "string" ? JSON.parse(error) : error;
  if (errorMsg) {
    alert = { success: false, message: errorMsg }
  } else if (error.data && error.data.message) {
    alert = { success: false, message: (error.data && error.data.message) || 'Request Failed' }
  }
  if (alert) {
    yield setAlertAndLoading(false, alert, false)
  }
}
export const getDateInFormat = (iDate) => {
  return moment(iDate).format(DATE_FORMAT)
}
export const getDateTimeInFormat = (iDate) => {
  return moment(iDate).format(DATE_TIME_FORMAT)
}
export const getDateInExportFormat = (iDate) => {
  return moment(iDate).format(EXPORT_DATE_FORMAT)
}
export const getUserDetails = (obj, attribute) => {
  if ((attribute ? obj[attribute] : obj)) {
    const { firstname, lastname, designation, department_name } = attribute ? obj[attribute] : obj;
    attribute = attribute || ''
    let creator = `${firstname} ${lastname || ''}`;
    obj[attribute + '_listname'] = `${firstname} ${lastname && lastname.length > 0 ? lastname[0] : ''}`;
    obj[attribute + '_designation_only'] = `${designation ? `${designation}, - `:  ''}${department_name || ''}`
    obj[attribute + '_designation'] = `${obj[attribute + '_listname']}, ${obj[attribute + '_designation_only']}`
    obj[attribute + '_name'] = creator;
  }
}
export function* requestHandler(callback, errorMsg, showPregress = true) {
  let token = Random.generate(8);
  try {
    if (showPregress) {
      yield put(commonAction.startProgress(token))
    }
    yield callback()
    if (showPregress) {
      yield put(commonAction.stopProgress(token))
    }
  } catch (error) {
    console.log('requestHandler', error);
    if (showPregress) {
      yield put(commonAction.stopProgress(token))
    }
    yield onSagaErrorCatch(error, errorMsg);
  }
}
export function* fetchDepartments() {
  yield requestHandler(function* () {
    let res = yield axios.get('/consoles/department_list');
    res = (res.department_list || []).map((_) => ({ label: _, id: _ }));
    res.sort(sortByProperty.bind(null, 'label'))
    yield put(commonAction.setDepartments(res))
  }, "Failed to Fetch Departments", false);
}
export const SagaRegistry = new SagaRegistryHelper();
export const ReducerRegistry = new ReducerRegistryHelper();
export const commonAction = actions;
ReducerRegistry.register('common', commonSlice.reducer);

const getState = (state) => state.common;

export const isLoading = (state) => {
  const _state = getState(state);
  return _state.loading
}
export const getFullLoading = (state) => {
  const _state = getState(state);
  return _state.fullLoading
}
export const getAlert = (state) => {
  const _state = getState(state);
  return _state.notification
}
export const isProgress = (state) => {
  const _state = getState(state);
  return _state.progressTokens.length > 0
}
export const dateSorter = (attribute, itemA, itemB) => {
  const isAfter = moment(itemA[attribute]).isAfter(itemB[attribute])
  const isBefore = moment(itemB[attribute]).isAfter(itemA[attribute])
  return isAfter ? -1 : (isBefore ? 1 : 0)
}
// export const getDepartments = (state) => {
//   const _state = getState(state);
//   return _state.departments
// }
export const sortByProperty = (attribute, itemLeft, itemRight) => {
  let leftVal = itemLeft[attribute] ? itemLeft[attribute].toLowerCase() : '';
  let rightVal = itemRight[attribute] ? itemRight[attribute].toLowerCase() : '';
  return (leftVal < rightVal) ? -1 : (rightVal > leftVal ? 1 : 0)
}
export * from "./axios"