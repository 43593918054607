import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ContinuerLogo } from "../../../components/Assets"
import { CentralTableComponent } from "../../CentralStatsTable";
import { axios } from "../../../store"

const Field = { attribute: 'dateRange', type: 'select' }

const Columns = [
  { label: '', attribute: 'department', headerClassName: 'txt-left', width: '10%', isLabel: true },
  { label: 'Total', attribute: 'total', headerClassName: 'bgDDFFFF', width: '10%' },
  { label: 'Closed', attribute: 'closed', headerClassName: 'published', width: '10%' },
  { label: 'InProgress', attribute: 'inprogress', headerClassName: 'orange-f77a50', width: '10%' },
  { label: 'Open', attribute: 'open', headerClassName: 'cFE3333', width: '10%' },
  { label: 'Minimal', attribute: 'minimal', headerClassName: 'c777', width: '10%' },
  { label: 'Other', attribute: 'other', headerClassName: 'c777', width: '10%' },
]

export function ContinuerStats({ departments, user, imageSrc }) {
  const initialRows = departments.map((deptObj) => {
    return (
      { 'department': deptObj.id, 'total': 0, 'closed': 0, 'inprogress': 0, 'open': 0, 'minimal': 0, 'other': 0 }
    )
  })
  const [state, setState] = useState([...initialRows])

    const getDataFromServer = async (dateRange) => {
        let payload = {
            "date_range_type": "last_365_days",
            "start_date": "01-11-2023",
            "end_date": "20-11-2024"
        }
        if (dateRange && dateRange.id) {
            payload.date_range_type = dateRange.id
            // alert(JSON.stringify(dateRange + ' id ' + dateRange.id))
        }
        let res = await axios.post('/continuers/department_dashboard', payload);
        if (res.department_status) {
            let initRows = [...initialRows]
            let continuer_res = []
            let updatedResult = []
            const brandname = user.ent_org.brandname;
            // const capitalizedBrandname = brandname.charAt(0).toUpperCase() + brandname.slice(1).toLowerCase();
            let totalRow = { department: brandname, total: 0, closed: 0, inprogress: 0, open: 0, minimal: 0, other: 0, };

            for (const dept_key in res.department_status) {
                if (Object.hasOwnProperty.call(res.department_status, dept_key)) {
                    const status_obj = res.department_status[dept_key].statuses
                    const total = status_obj.total || 0
                    const closed = status_obj.closed || 0
                    const inprogress = status_obj.inprogress || 0
                    const open = status_obj.open || 0
                    const minimal = status_obj.minimal || status_obj.default || 0
                    const other = total - (closed + inprogress + open + minimal)

                    let row = {
                        department: dept_key,
                        total: total,
                        closed: closed,
                        inprogress: inprogress,
                        open: open,
                        minimal: minimal,
                        other: other,
                    }
                    continuer_res.push(row)
                    // // Accumulate totals for totalRow
                    Object.keys(totalRow).forEach((key) => {
                        if (key !== 'department') {
                            totalRow[key] += row[key]
                        }
                    })

                }
                let updatedRows = initRows.map(existingRow => {
                    const matchingRow = continuer_res.find(newRow => newRow.department === existingRow.department);

                    return matchingRow ? { ...existingRow, ...matchingRow } : existingRow;
                });
                updatedRows.sort((a, b) => a.department.toLowerCase().localeCompare(b.department.toLowerCase()));
                updatedRows.unshift(totalRow);
                // Filter out rows with all 0 values except for 'department'
                updatedRows = updatedRows.filter(row => {
                    return Object.entries(row).some(([key, value]) => key !== 'department' && value !== 0);
                });
                // Replace 0 values with '-'
                setTimeout(()=>{
                    updatedRows.forEach((row) => {
                        Object.keys(row).forEach((columnKey) => {
                            if (row[columnKey] === 0 || row[columnKey] === '0') {
                                row[columnKey] = '-';
                            }
                        });
                    });
                },0)
                updatedResult = [...updatedRows];
            }
            setState(updatedResult)
        }
    }
    useEffect(() => {
        getDataFromServer()
    }, [])
    return (
        <>
            <CentralTableComponent
                componentID={'continuer_status'}
                componentStyle={{ marginBottom: '50px', marginLeft: '30px', maxWidth: '55%', }}
                logoComponent={<ContinuerLogo className='logo' />}
                className=''
                title='Continuity Test Status'
                topBarField={Field}
                rows={state}
                Columns={Columns}
                tableField={Field}
                tableID='continuer-table'
                tableClassName='arc-stats-tbl'
                onDateChange={getDataFromServer}
                imageSrc={imageSrc}
                exportCompWidth={1100}
                rightPadding={120}
                TopHeaderBlack={true}
                isReduceRowHeight={true}
                isNotMaxHeight={true}
            />
        </>
    )
}