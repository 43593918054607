import { put, takeEvery, select } from "redux-saga/effects";
import { SagaRegistry, requestHandler, setAlertAndLoading, axios, getDateInFormat, getDateTimeInFormat, dateSorter, getUserDetails } from "../common";
import { DocEndPoints } from "./endpoints";
import { docActions } from "./slice";
import { getDepartments } from ".";
import { getCurrentUser } from "../user"

const fetchS3ObjectAsBlob = (url, type) => {
  return fetch(url, { method: "GET", headers: axios.getAuthTokenHeader() }).then(_ => _.blob())
}
const mapContent = (departments, types, classifications, statusList, _) => {
  const content = { ..._, more: 'Info', key: _.id };
  content.updated_at = getDateInFormat(content.updated_at)
  content.created_at = getDateInFormat(content.created_at)
  getUserDetails(content, 'creator');
  getUserDetails(content, 'updater');
  if (Array.isArray(departments)) {
    const dept = departments.find((_) => _.id === content.department_name);
    if (dept) content.department_name = dept;
  }
  if (Array.isArray(types)) {
    const type = types.find((_) => _.id === content.doc_type);
    if (type) content.doc_type = type;
  }
  if (Array.isArray(statusList)) {
    const status = statusList.find((_) => _.id === content.status);
    if (status) content.status = status;
  }
  if (Array.isArray(classifications)) {
    const classif = classifications.find((_) => _.id === content.classification);
    if (classif) content.classification = classif;
  }
  content.doc_linkname = `${content.doc_id}-${content.name}`;
  content.doc_link = `/D/${content.guid}`;
  content.doc_history = [];
  content.change_history = [];
  return content
}
const fetchDepts = function* () {
  yield requestHandler(function* () {
    let res = yield axios.get('/consoles/department_list');
    res = (res.department_list || []).map((_) => ({ label: _, id: _ }));
    yield put(docActions.setDepts(res))
  }, "Failed to Fetch Departments", false);
}
const fetchDocStatusList = function* () {
  yield requestHandler(function* () {
    let res = yield axios.get('/documents/status_list');
    res = (res.statuses || []).map((_) => ({ label: _, id: _ }));
    yield put(docActions.setDocStatus(res))
  }, "Failed to Fetch Document Status List", false);
}

function* fetchDateRangeEnums() {
  yield requestHandler(function* () {
    // const res = yield axios.get(`/incidents/date_range_list`);
    let list = [
      { id: 'last_7_days', label: 'Last 7 Days' },
      { id: 'last_week', label: 'Last Week (Sun-Sat)' },
      { id: 'last_30_days', label: 'Last 30 Days' },
      { id: 'last_month', label: 'Previous Month' },
      { id: 'last_90_days', label: 'Last 90 Days' },
      { id: 'last_year', label: 'Previous Quarter' },
      { id: 'last_365_days', label: 'Last 365 Days' },
      { id: 'previous_year', label: 'Previous Year (Apr-Mar)' },
      { id: 'over_all', label: 'Entire History' },
    ]
    yield put(docActions.setDateRangeEnums(list))
  }, "Failed to load Date Range list");
}
function* fetchStatusTypes() {
  yield requestHandler(function* () {
    const res = yield axios.get(`/incidents/status_list`);
    let list = res.status_list ? res.status_list : [];
    list = Array.isArray(list) ? list : [];
    list = list.map((_) => {
      return { id: _, label: _ };
    })
    yield put(docActions.setStatusTypes(list))
  }, "Failed to load Status list");
}
function* fetchSeverityTypes() {
  yield requestHandler(function* () {
    const res = yield axios.get(`/incidents/severity_list`);
    let list = res.severity_list ? res.severity_list : [];
    list = Array.isArray(list) ? list : [];
    list = list.map((_) => {
      return { id: _, label: _ };
    })
    yield put(docActions.setSeverityTypes(list))
  }, "Failed to load Severity list");
}
function* fetchCentralGuides() {
  yield requestHandler(function* () {
    let path = 'active_guide_list';
    let res = yield axios.get('/guides/' + path);
    const departments = yield select(getDepartments);
    if (res) {
      res = res.guide_list ? res.guide_list : res;
      res = Array.isArray(res) ? res : [];
      res.sort(dateSorter.bind(null, 'updated_at'))
      // res = res.map((_, i) => {
      //   const guide = mapContent(departments, _, i);
      //   guide.link = '/G/' + (guide.id);
      //   return guide;
      // });
    }
    yield put(docActions.setGuides(res))
  }, "Failed to Fetch Central Guides List");
}
function* fetchCentralGuide({ payload }) {
  yield requestHandler(function* () {
    let { id } = payload;
    let res = yield axios.get('/guides/show_guide?guide_id=' + id);
    const departments = yield select(getDepartments)
    if (res) {
      const guide_content = mapContent(departments, res)
      yield put(docActions.setShowGuide({ guide: guide_content }))
    }
  }, "Failed to fetch Central Guide");
}


SagaRegistry.register(function* docSaga() {
  yield takeEvery("document/fetchDepts", fetchDepts);
  yield takeEvery("document/fetchDocStatusList", fetchDocStatusList);
  yield takeEvery("document/fetchDateRangeEnums", fetchDateRangeEnums);
  yield takeEvery("document/fetchStatusTypes", fetchStatusTypes);
  yield takeEvery("document/fetchSeverityTypes", fetchSeverityTypes);
  yield takeEvery("document/fetchCentralGuides", fetchCentralGuides);
  yield takeEvery("document/fetchCentralGuide", fetchCentralGuide);
})