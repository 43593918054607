import "./saga";
export * from "./slice";
export * from "./helpers";

const filterProps = ["name", "email", "department_name", "roleName"]

const getUserState = (state) => state.user;

export const getLogInStatus = (state) => {
  let userState = getUserState(state);
  return userState.logInStatus
}
export const isLoggedOut = (state) => {
  let userState = getUserState(state);
  return userState.isLoggedOut;
}
