import React, { useEffect, useState } from "react";
import "./Table.scss"
import { getCurrentUser } from "../../store";
import { useSelector } from "react-redux";

export const CentralTable = (props) => {
    const { rows, Columns, Field, title, tableID, tableClassName} = props;
    const user = useSelector(getCurrentUser);
    const brandname = user.ent_org.brandname;
    // const capitalizedBrandname = brandname.charAt(0).toUpperCase() + brandname.slice(1).toLowerCase();

    const getStatusRiskColor = (status) => {
        switch (status) {
            case 'Pending':
                return '#CC0000';
            case 'Submitted':
                return '#FF8888';
            case 'Revision':
                return '#FF8888';
            case 'Minimal':
                return '#FF8888';
            case 'High':
                return '#FF8888';
            case 'Evaluating':
                return '#FF9900';
            case 'Evaluated':
                return '#FF9900';
            case 'Low':
                return '#FF9900';
            case 'Medium':
                return '#FF9900';
            case 'Complete':
                return '#228822';
            case 'Critical':
                return '#CC0000';
            case 'Default':
                return 'gray';
            default:
                return '';
        }
    };


    return (
        <table className={`${tableClassName} stats-tbl`} id={tableID}>
            <thead>
                <tr className="table-column-row-1 ">
                    {
                        Columns.map((col) => {
                            return (
                                <th style={{ width: col.width || '150px', }} className={`${props.isExport || props.isReduceRowHeight ? 'f8' : 'f7'} exo2 bold header-cell ${col.headerClassName}`} dangerouslySetInnerHTML={{ __html: col.label }} ></th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>

                {
                    rows.length === 0 ? (
                        <tr  className="table-row-1" >
                        <td className="f7 bold cell v-ctr h-ctr exo2">{brandname}</td>
                            <td colSpan={Columns.length} className={`${props.isExport || props.isReduceRowHeight ? 'f8':'f7'} reg cell c238787 v-ctr h-ctr`} style={{paddingLeft:'20px'}}>
                                No data to display!
                            </td>
                        </tr>
                    )
                        :
                        (
                            rows.map((row, i) => {
                                return (
                                    <tr key={`table-key-${tableID}-${i}`} className={`table-row-1  ${props.isExport ? 'reduce-row-height' : `${props.isReduceRowHeight? 'fixed-row-height':'row-height'}`}`} >
                                        {
                                            Columns.map((col) => {
                                                let value = row[col.attribute];
                                                let isLabelCell = col.attribute === 'label' || col.isLabel;
                                                let isIndicatorCell = col.isIndicator
                                                return (

                                                    <td key={col.attribute} className={`f7 reg cell c238787  ${isLabelCell ? row.labelClassName : ''}`}>
                                                        <div className={`row w-100 h-100  ${isLabelCell || isIndicatorCell ? 'v-ctr' : 'h-ctr'}`}>
                                                            {isIndicatorCell && value && (
                                                                <div style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: getStatusRiskColor(value), display: 'inline-block', marginRight: '8px' }}></div>
                                                            )}
                                                            {/* for the first row, all values should be in black */}
                                                            {
                                                                (i===0)
                                                                ?<span className={`exo2 ${col.cellClassName} ${isIndicatorCell ? '' : 'bold'} ${(col.isHeaderCellBlack || props.TopHeaderBlack) ? 'app-color-header' : ''} ${props.isExport  || props.isReduceRowHeight ? 'f8' : 'f7'}`}>{value}</span>
                                                                :<span className={`exo2 ${col.cellClassName} ${isIndicatorCell ? '' : 'bold'} ${props.isExport || props.isReduceRowHeight ? 'f8':'f7'}`}>{value}</span>
                                                            }
                                                        </div>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        )
                }

            </tbody>
        </table>
    )
}