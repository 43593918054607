import { useState } from "react";
import { Modal } from 'antd';
import GroupSelect from './GroupingSelect';
import SimpleSelect from './SimpleSelect';
import { exportGroupToPDF, exportGroupToPNGInZip } from './Helpers'
import './exportPopup.scss'

export default function ExportStatsDialogNew({ showExport, setShowExport }) {
    const [selectedExports, setSelectedExports] = useState({
        type: '',
        modules: []
    })
    const handleTypeChange = (file_type) => {
        setSelectedExports((prev) => {
            return (
                {
                    ...prev,
                    type: file_type,
                }
            )
        })
    }
    const handleModuleChange = (selectedModules) => {
        setSelectedExports((prev) => {
            return (
                {
                    ...prev,
                    modules: selectedModules,
                }
            )
        })
    }
    const handleOk = () => {
        downloadModule(selectedExports.modules, selectedExports.type)
        setShowExport(false);
    };
    const handleCancel = () => {
        setShowExport(false);
    };

    const downloadModule = async (modules, type) => {
        if (type === 'pdf') {
            exportGroupToPDF(modules)
            return
        }
        else {
            exportGroupToPNGInZip(modules)
            return
        }
    };// Check if both type and modules are selected
    const isExportEnabled = selectedExports.type !== '' && selectedExports.modules.length > 0;

    return (
        <>
            <Modal
                title={<div className='f3 exo2 bold c238787 mb2'>Export Stats</div>}
                open={showExport} onOk={handleOk}
                onCancel={handleCancel}
                okText='Export'
                closable={false}
                okButtonProps={{ style: { borderRadius: '0.2em', padding:'0 5px' ,display: isExportEnabled? 'inline-block': 'none' } }} 
                cancelButtonProps={{ style: {color:'blue', borderRadius: '0.2em' , border: 'none', padding: '0', background: 'none', marginRight:'8px'} }} 
                style={{
                    top: '50%',
                    transform: 'translateY(-50%)', 
                }}
                className="custom-modal "
                footerStyle={{ display: 'flex', justifyContent:'flex-end' }} 
            >
                <SimpleSelect onChange={handleTypeChange} />
                <GroupSelect onChange={handleModuleChange} />
                <hr style={{ border: 'none', backgroundColor: '#EFECEC', height: '1px', marginTop: 0 }} />
            </Modal>
        </>
    )
}
