import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GuideSideNavBar } from "./GuideSideNavBar"
import Introduction from "./GuideIntroduction"
import AuditComponent from "./Audit"
import DocumentComponent from "./Document"
import RiskComponent from "./Risk"
import ComplianceComponent from "./Compliance"
import IncidentComponent from "./Incidents"
import ContinuityComponent from "./Continuity"
import EducationComponent from "./Education"
import RecordsComponent from "./Records"
import './style.scss'
import { docActions, getActiveGuides } from "../../store";

export default function CentralGuide() {
    const [moduleContent, setModuleContent] = useState({})
    const [isExpanded, setIsExpanded] = useState(null);
    const dispatch = useDispatch()
    const guideRef = useRef(null);
    const documentorRef = useRef(null);
    const auditorRef = useRef(null);
    const mitigatorRef = useRef(null);
    const arcRef = useRef(null);
    const educatorRef = useRef(null);
    const investigatorRef = useRef(null);
    const continuerRef = useRef(null);
    const recordRef = useRef(null);
    const activeGuides = useSelector(getActiveGuides)
    const scrollToRef = (ref) => {
        if (ref.current) {
            setTimeout(() => {
                ref.current.scrollIntoView({ behavior: 'smooth' });
            }, 200)
        }
    };
    const handleModuleClick = (moduleId) => {
        switch (moduleId) {
            case 'Guide':
                scrollToRef(guideRef);
                break;
            case 'Document':
                setIsExpanded('Document')
                scrollToRef(documentorRef);
                break;
            case 'Compliance':
                setIsExpanded('Compliance')
                scrollToRef(arcRef);
                break;
            case 'Audit':
                setIsExpanded('Audit')
                scrollToRef(auditorRef);
                break;
            case 'Incident':
                setIsExpanded('Incident')
                scrollToRef(investigatorRef);
                break;
            case 'Education':
                setIsExpanded('Education')
                scrollToRef(educatorRef);
                break;
            case 'Continuity':
                setIsExpanded('Continuity')
                scrollToRef(continuerRef);
                break;
            case 'Risk':
                setIsExpanded('Risk')
                scrollToRef(mitigatorRef);
                break;
            case 'Records':
                setIsExpanded('Records')
                scrollToRef(recordRef);
                break;
            default:
                break;
        }
    };

    const getModuleWiseGuides = () => {
        activeGuides.forEach((guide) => {
            const guide_details = guide.guide_details
            const sorted_guide_details = Array.isArray(guide_details)
                ? [...guide_details].sort((a, b) => a.order_no - b.order_no)
                : [];
            setModuleContent((_) => ({
                ..._,
                [guide.module_name]: sorted_guide_details,
                [`${guide.module_name}_title`]: guide.title
            }))
        })
    }
    useEffect(() => {
        dispatch(docActions.fetchCentralGuides())
    }, [])
    useEffect(() => {
        if (Array.isArray(activeGuides) && activeGuides.length > 0) {
            getModuleWiseGuides();
        }
    }, [activeGuides]);
  
    return (
        <div className="row w-100 h-100 o-auto">
            <div className="w-10 sidebar ">
                <GuideSideNavBar handleModuleClick={handleModuleClick} />
            </div>                    
            <div className="w-90 h-100">
                <div className="w-90 p5" style={{ paddingBottom: '20px' }}>
                    <div ref={guideRef}><Introduction guideContent={moduleContent.Introduction} title={moduleContent.Introduction_title} /></div>
                    <div ref={documentorRef}><DocumentComponent guideContent={moduleContent.Documenter} title={moduleContent.Documenter_title} isExpanded={isExpanded} setIsExpanded={setIsExpanded}/></div>
                    <div ref={auditorRef}><AuditComponent guideContent={moduleContent.Auditor} title={moduleContent.Auditor_title} isExpanded={isExpanded} setIsExpanded={setIsExpanded} /></div>
                    <div ref={mitigatorRef}><RiskComponent guideContent={moduleContent.Mitigator} title={moduleContent.Mitigator_title} isExpanded={isExpanded} setIsExpanded={setIsExpanded} /></div>
                    <div ref={arcRef}><ComplianceComponent guideContent={moduleContent.Arc} title={moduleContent.Arc_title} isExpanded={isExpanded} setIsExpanded={setIsExpanded} /></div>
                    <div ref={educatorRef}><EducationComponent guideContent={moduleContent.Educator} title={moduleContent.Educator_title} isExpanded={isExpanded} setIsExpanded={setIsExpanded} /></div>
                    <div ref={investigatorRef}><IncidentComponent guideContent={moduleContent.Investigator} title={moduleContent.Investigator_title} isExpanded={isExpanded} setIsExpanded={setIsExpanded} /></div>
                    <div ref={continuerRef}><ContinuityComponent guideContent={moduleContent.Continuer} title={moduleContent.Continuer_title} isExpanded={isExpanded} setIsExpanded={setIsExpanded} /></div>
                    <div ref={recordRef}><RecordsComponent guideContent={moduleContent.Records} title={moduleContent.Records_title} isExpanded={isExpanded} setIsExpanded={setIsExpanded} /></div>
                </div>
            </div>
        </div>
    )
}