import { useEffect, useState } from "react"
import { DocumenterLogo } from "../../../components/Assets"
import { CentralTableComponent } from "../../CentralStatsTable";
import { axios } from "../../../store"

const Field = { attribute: 'dateRange', type: 'select' }
const DeptColumns = [
    { attribute: 'department', label: '', width: '9%', headerClassName: 'c238787 txt-left ', isLabel: 'true' },
    { attribute: 'overall', label: 'Total', width: '8%', headerClassName: 'bgDDFFFF' },
    { attribute: 'published', label: 'Published', width: '8%', headerClassName: 'published' },
    { attribute: 'approved', label: 'Approved', width: '8%', headerClassName: 'orange-f77a50' },
    { attribute: 'reviewed', label: 'Review', width: '8%', headerClassName: 'yellow-f5a645' },
    { attribute: 'draft', label: 'Draft', width: '8%', headerClassName: 'cFE3333' },
];
export function DocumentorStats(props) {
    const { user } = props
    const [responseData, setResponseData] = useState({})
    const [deptWiseRows, setDeptWiseRows] = useState([])
       
    const handleFetchDocumenterDeptStats = async (dateRange) => {
        let payload = {
            "date_range_type": "last_365_days",
            "start_date": "01-11-2023",
            "end_date": "20-11-2024"
        }
        if (dateRange && dateRange.id) {
            payload.date_range_type = dateRange.id
        }
        let res = await axios.post('/documents/central_dashboard', payload);
        setResponseData(res)
        
    }
    useEffect(()=>{
        handleFetchDocumenterDeptStats()
    },[])

    useEffect(()=>{
        let tableDataToDisplay = tranformDataIntoTable(responseData, user)
        if(tableDataToDisplay){
            setDeptWiseRows(tableDataToDisplay)
        }
    },[responseData])
       
    return (
        <>
            <CentralTableComponent
                componentID={'documenter_dept_status'}
                componentStyle={{ minWidth: '40%', maxWidth: '50%' }}
                logoComponent={<DocumenterLogo className='logo' />}
                className=''
                title='Documentation Status'
                topBarField={""}
                rows={deptWiseRows}
                Columns={DeptColumns}
                tableField={Field}
                tableID='document-dept-table'
                tableClassName='arc-stats-tbl'        
                onDateChange={handleFetchDocumenterDeptStats}
                imageSrc={props.imageSrc}
                exportCompWidth={1100}
                rightPadding={230}
                TopHeaderBlack={true}
                isNotMaxHeight={true}
            />
            
        </>
    )
}
const tranformDataIntoTable = (res, user) => {
    const brandname = user.ent_org.brandname;
    // const capitalizedBrandname = brandname.charAt(0).toUpperCase() + brandname.slice(1).toLowerCase();
    
    let output=[]
        if(res && res.department_status){
            Object.keys( res.department_status).forEach((department_key)=>{
                const department_wise_obj = res.department_status[department_key]
                let row={department:'', overall: '-', published:'-', approved:'-', reviewed:'-', draft:'-' }
                row.department = department_key
                row.published = department_wise_obj.published || 0
                row.approved = department_wise_obj.approved || 0
                row.reviewed = department_wise_obj.reviewed || 0
                row.draft = department_wise_obj.draft || 0
                row.overall = (row.published + row.approved + row.reviewed + row.draft )

                output.push(row)

            })
        }

         let totalRow = {department: brandname,overall: 0, published:0, approved:0, reviewed:0, draft:0 }
        for(let departmentObj of output){
            totalRow.overall += departmentObj.overall;
            totalRow.published += departmentObj.published;
            totalRow.approved += departmentObj.approved;
            totalRow.reviewed += departmentObj.reviewed;
            totalRow.draft += departmentObj.draft;
        }
        output.sort((a, b) => a.department.toLowerCase().localeCompare(b.department.toLowerCase()));
        output.unshift(totalRow)
        output = output.filter( row =>{
            return Object.entries(row).some(([key, value]) => (
                key!== 'department' && value !== 0
            ))
        })
        output.forEach((row) => {
            Object.keys(row).forEach((columnKey) => {
                if(row[columnKey] === 0 || row[columnKey] === '0'){
                    row[columnKey] = '-'
                }
            })
        })
        return output
}
