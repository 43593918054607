
import { useEffect, useState } from "react"
import { ArcLogo } from "../../../components/Assets"
import { CentralTableComponent } from "../../CentralStatsTable";
import { axios,  } from "../../../store"

const Field = { attribute: 'dateRange', type: 'select' }

export default function DepartmentWisePriority(props) {
    const [responseData, setResponseData] = useState({})

    const { user, statusList, departments, priorities } = props
    const deptInitialRows = departments.map((deptObj) => {
        return (
            { 'department': deptObj.id, 'total': 0, 'complete': 0, 'inprogress': 0, 'pending': 0, 'other': 0 }
        )
    })
    deptInitialRows.unshift({ 'department': user.ent_org.brandname, 'total': 0, 'complete': 0, 'inprogress': 0, 'pending': 0, 'other': 0 })
    const DeptPriorityColumns = [
        { label: '', attribute: 'department', width: '10%', headerClassName: 'txt-left', isLabel: true },
        { label: 'Total', attribute: 'total', width: '8%', headerClassName: 'bgDDFFFF' },
        { label: 'Critical', attribute: 'critical', width: '8%', headerClassName: 'critical' },
        { label: 'High', attribute: 'high', width: '8%', headerClassName: 'high' },
        { label: 'Medium', attribute: 'medium', width: '8%', headerClassName: 'medium' },
        { label: 'Low', attribute: 'low', width: '8%', headerClassName: 'low' },
        { label: 'Minimal', attribute: 'minimal', width: '8%', headerClassName: 'minimal' },
    ]
    const [tableData, setTableData] = useState([...deptInitialRows])
    const getDataFromServer = async (dateRange) => {
        let payload = {
            "date_range_type": "last_365_days",
            "start_date": "01-11-2023",
            "end_date": "20-11-2024"
        }
        if (dateRange && dateRange.id) {
            payload.date_range_type = dateRange.id
        }
        let res = await axios.post('/tasks/arc_central_dashboard', payload)
        setResponseData(res)
    }
    useEffect(() => {
        getDataFromServer()
    }, [])
    useEffect(() => {
        let tempTableData = getDataFromResponse(responseData, user, departments)
        setTableData([...tempTableData])
    }, [responseData])

    const tableID = 'arc-dept-priority-table'
    return (
        <>
            <CentralTableComponent
                componentID={'arc_department_wise_priority_status'}
                componentStyle={{ minWidth: '50%', maxWidth: '55%' }}
                logoComponent={<ArcLogo className='logo' />}
                className=''
                title='Department-wise Priority Status'
                topBarField={Field}
                rows={tableData}
                Columns={DeptPriorityColumns}
                tableField={Field}
                tableID={tableID}
                tableClassName='arc-stats-tbl'
                onDateChange={getDataFromServer}
                imageSrc={props.imageSrc}
                exportCompWidth={1100}
                rightPadding={145}
                TopHeaderBlack={true}
                isReduceRowHeight={true}
                isNotMaxHeight={true}
            />
        </>
    )
}


const getDataFromResponse = (res, user, departments) => {
    if (res.dept_priority_list) {
        let dept_priority_compliance = []
        const brandname = user.ent_org.brandname;
        // const capitalizedBrandname = brandname.charAt(0).toUpperCase() + brandname.slice(1).toLowerCase();
        let totalRow = { department: brandname, total: 0, critical: 0, high: 0, medium: 0, low: 0, minimal: 0 };
        let departmentMap = {};

        for (const key in res.dept_priority_list) {
            let correctedKey = key.replace(/\s/g, ""); // Remove spaces from the key

            // let isDepartmentValid = departments.find(item => item.id === correctedKey)
            // if (!isDepartmentValid) {
            //     continue
            // }
            const normalizedKey = correctedKey.toLowerCase(); // Normalize the department name
            const priorityStatus = res.dept_priority_list[key];
            const total = parseInt(priorityStatus.total, 10) || 0;
            const critical = parseInt(priorityStatus.Critical, 10) || 0;
            const high = parseInt(priorityStatus.High, 10) || 0;
            const mediumVal = parseInt(priorityStatus.Medium, 10) || 0;
            const defaultVal = parseInt(priorityStatus.Default, 10) || 0;
            const low = parseInt(priorityStatus.Low, 10) || 0;
            const minimal = parseInt(priorityStatus.Minimal, 10) || 0;
            const medium = mediumVal + defaultVal

            if (departmentMap[normalizedKey]) {
                // If department already exists, add values to existing department
                departmentMap[normalizedKey].total += total;
                departmentMap[normalizedKey].critical += critical;
                departmentMap[normalizedKey].high += high;
                departmentMap[normalizedKey].medium += medium;
                departmentMap[normalizedKey].low += low;
                departmentMap[normalizedKey].minimal += minimal;

            } else {
                // If department does not exist, create a new entry
                departmentMap[normalizedKey] = {
                    department: correctedKey,
                    total:total,
                    critical: critical,
                    high: high,
                    medium: medium,
                    low: low,
                    minimal: minimal,
                };
            }
            dept_priority_compliance = Object.values(departmentMap)
            // Update totalRow based on aggregated values
            totalRow.total = dept_priority_compliance.reduce((sum, department) => sum + department.total, 0);
            totalRow.critical = dept_priority_compliance.reduce((sum, department) => sum + department.critical, 0);
            totalRow.high = dept_priority_compliance.reduce((sum, department) => sum + department.high, 0);
            totalRow.medium = dept_priority_compliance.reduce((sum, department) => sum + department.medium, 0);
            totalRow.low = dept_priority_compliance.reduce((sum, department) => sum + department.low, 0);
            totalRow.minimal = dept_priority_compliance.reduce((sum, department) => sum + department.minimal, 0);

        }
        dept_priority_compliance.sort((a, b) => a.department.toLowerCase().localeCompare(b.department.toLowerCase()));

        dept_priority_compliance.unshift(totalRow);
        dept_priority_compliance = dept_priority_compliance.filter(row => {
            return Object.entries(row).some(([key, value]) =>( key !== 'department' && value !== 0));
        });
        dept_priority_compliance.forEach((row)=>{
            Object.keys(row).forEach((columnKey) => {
                if(row[columnKey] === 0 || row[columnKey] === '0'){
                    row[columnKey] = '-'
                }
            }) 
        })
        return dept_priority_compliance
    }
    else {
        return []
    }
}