import { EducatorStats } from "./EducatorStats";
import { DocumentorStats } from "./DocumentorStats";
import { InvestigatorStats } from "./InvestigatorStats";
import { AuditorStats } from "./AuditorStats";
import { ArcStats } from "./ArcStats";
import { ContinuerStats } from "./ContinuerStats";
import React, { useEffect, useRef, useState } from "react";
import { Button, OvalLoading } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getDepartments, getDocStatusList, getSeverityTypes, isOrganizer, commonAction, fetchDepartments, isAccessor, axios, docActions } from "../../store";
import ExportStatsDialog from "./ExportsFormDialog"
import { SideNavBar } from "../../components";

export function CentralStats(props) {
    const [imageSrc, setImageSrc] = useState('');
    const [moduleClicked, setModuleClicked] = useState('')
    const [showExport, setShowExport] = useState(false)
    const dispatch = useDispatch()
    const documentorRef = useRef(null);
    const arcRef = useRef(null);
    const auditorRef = useRef(null);
    const investigatorRef = useRef(null);
    const educatorRef = useRef(null);
    const continuerRef = useRef(null);
    const user = useSelector(getCurrentUser);
    const departments = useSelector(getDepartments)
    const statusList = useSelector(getDocStatusList)

    const isUserOrganizer = isOrganizer(user)
    const isUserAccessor = isAccessor(user)
    const priorities = ["Critical", "High", "Medium", "Low", "Minimal", "Default"]
    const severities = useSelector(getSeverityTypes)

    useEffect(() => {
        dispatch(docActions.fetchDepts())
        dispatch(docActions.fetchSeverityTypes())
    }, [])

    const scrollToRef = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const ScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleModuleClick = (moduleId) => {
        switch (moduleId) {
            case 'fusion':
                ScrollToTop();
            case 'documenter':
                scrollToRef(documentorRef);
                break;
            case 'arc':
                scrollToRef(arcRef);
                break;
            case 'auditor':
                scrollToRef(auditorRef);
                break;
            case 'investigator':
                scrollToRef(investigatorRef);
                break;
            case 'educator':
                scrollToRef(educatorRef);
                break;
            case 'continuer':
                scrollToRef(continuerRef);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (moduleClicked) {
            handleModuleClick(moduleClicked)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleClicked]);

    const [isDepartsmentFetched, setIsDepartmentFetched] = useState(false)
    useEffect(() => {
        if (!isDepartsmentFetched && Array.isArray(departments) && departments.length > 0) {
            setIsDepartmentFetched(true)
        }
    }, [departments])


    useEffect(() => {
        if (user) {
            if (user.logoBlob) {
                const imageUrl = URL.createObjectURL(user.logoBlob);
                setImageSrc(imageUrl);
            }

        }
    }, [user])

    return (
        <>
            <div className='row w-100 h-100 o-auto'>
                <SideNavBar moduleClicked={moduleClicked} setModuleClicked={setModuleClicked}/>

                <div className="h-100">
                    <div className=' v-ctr h-btn d-flex ' style={{ paddingLeft: '30px', height: '7%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)' }}>
                        <div className='f3 c238787  exo2' >Stats</div>
                        <div className="f8" style={{ marginRight: '30px' }}>Export <Button font='f8' style={{ marginLeft: '10px' }} label='...' onClick={() => { setShowExport(true) }} /></div>
                    </div>
                    <div className='o-auto' style={{ 'height': '93%', position: 'relative' }} >
                        <div style={{ height: '90%', width: '100%', backgroundColor: 'rgba(255, 255, 255, 1)', position: 'fixed', display: 'none', zIndex: '999' }} id='loader-component'>
                            <OvalLoading />
                        </div>
                        <div ref={documentorRef} className="pt1" style={{ paddingTop: '25px', }}>
                            <DocumentorStats user={user} departments={departments} statusList={statusList} imageSrc={imageSrc} />
                        </div>

                        {
                            isDepartsmentFetched &&
                            <div ref={arcRef} className="pt1">
                                <ArcStats user={user} departments={departments} statusList={statusList} priorities={priorities} imageSrc={imageSrc} />
                            </div>
                        }
                        <div ref={auditorRef} className="pt1">
                            <AuditorStats user={user} departments={departments} statusList={statusList} imageSrc={imageSrc} />
                        </div>
                        <div ref={investigatorRef} className="pt1">
                            <InvestigatorStats user={user} departments={departments} statusList={statusList} severities={severities} imageSrc={imageSrc} />
                        </div>
                        <div ref={educatorRef} className="pt1">
                            <EducatorStats user={user} departments={departments} statusList={statusList} imageSrc={imageSrc} />
                        </div>
                        {
                            isDepartsmentFetched &&
                            <div ref={continuerRef} className="pt1">
                                <ContinuerStats user={user} departments={departments} statusList={statusList} imageSrc={imageSrc} />
                            </div>
                        }
                    </div>

                    {
                        showExport &&
                        <ExportStatsDialog showExport={showExport} setShowExport={setShowExport} />

                    }


                </div>

            </div >
        </>

    )
}