import React, { useState } from 'react';
import { useNavigate, } from "react-router-dom";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { EditorOnlyRoute, HomeScreen, LoginScreen, PageNotFoundScreen } from "../screens";
import { CentralStats } from '../screens/CentralStats';
import CentralSupport from '../screens/CentralSupport';
import CentralGuide from '../screens/CentralGuide';


const CentralRouter = () => {
  return (
    <Routes>
      <Route path="/" index element={<Navigate replace to="/C/stats" />} />
      <Route path="/stats" index element={<CentralStats/>} />
      <Route path="/guide" index element={<CentralGuide />} />
      <Route path="/support" index element={<CentralSupport />} />
    </Routes>
  )
}

const MainRouter = (props) => {
  return (
    <HomeScreen>
      <Routes>
        <Route path="/C/*" index element={<CentralRouter/>} />
        <Route path="/" index element={<Navigate replace to="/C/stats" />} />
      </Routes>
     
    </HomeScreen>
  )
}


function AppRouter() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/login" element={<LoginScreen />} exact />
        <Route path="/page-not-found" element={<PageNotFoundScreen />} exact />
        <Route path="/*" element={<MainRouter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
