import React from 'react';
import "./style.scss"

const ButtonContainer = (props) => {
  const { className, variant, font, ...rest } = props
  return (
    <button className={`btn line-22 ${font || ''} ${variant === 'lite' ? 'bg-lite' : 'bg-primary'} ${className || ''}`} {...rest} onClick={props.onClick} >
      {props.children}
    </button>
  )
}
export const Button = (props) => {
  const { label, icon, iconColor, textFont, color, ...rest } = props
  const style = rest.disabled ? {} : { color: rest.variant === 'lite' ? color : '#FFF' }
  return (
    <ButtonContainer {...rest}>
      {Boolean(icon) && <i className={`${icon} icon ${Boolean(label) ? 'icon-text' : ''} ${iconColor || ''}`} />}
      {Boolean(label) && <span className={`label`} style={style}>{label}</span>}
    </ButtonContainer>
  )
}
