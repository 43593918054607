import React, { useState } from 'react';
import { GuideSection } from './GuideSection';

const IntroductionComponent = ({ guideContent, title }) => {
    return (
        <div className="intro">
            <h3 className="title bold exo2">{title ? title : 'Introduction'}</h3>
            <div className="description">
                {guideContent &&
                    guideContent.map((sectionContent) => (
                        <GuideSection key={sectionContent.id} sectionContent={sectionContent} />
                    ))}
            </div>
        </div>
    );
};

export default IntroductionComponent;
