
import { useEffect, useState } from "react"
import { ArcLogo } from "../../../components/Assets"
import { CentralTableComponent } from "../../CentralStatsTable";
import { axios} from "../../../store"

const Field = { attribute: 'dateRange', type: 'select' }

export default function DeptWiseCompliance(props) {
    const [responseData, setResponseData] = useState({})
    const { user, statusList, departments, priorities, } = props
    const deptInitialRows = departments.map((deptObj) => {
        return (
            { 'department': deptObj.id, 'total': 0, 'complete': 0, 'inprogress': 0, 'pending': 0, 'other': 0 }
        )
    })
    deptInitialRows.unshift({ 'department': user.ent_org.brandname, 'total': 0, 'complete': 0, 'inprogress': 0, 'pending': 0, 'other': 0 })
    const DeptColumns = [
        { label: '', attribute: 'department', width: '9%', headerClassName: 'txt-left', isLabel: true },
        { label: 'Total', attribute: 'total', width: '8%', headerClassName: 'bgDDFFFF' },
        { label: 'Complete', attribute: 'complete', width: '8%', headerClassName: 'published' },
        { label: 'InProgress', attribute: 'inprogress', width: '8%', headerClassName: 'orange-f77a50' },
        { label: 'Pending', attribute: 'pending', width: '8%', headerClassName: 'cFE3333' },
        { label: 'Other', attribute: 'other', width: '8%', headerClassName: 'c777' },
    ]
    const [tableData, setTableData] = useState([...deptInitialRows])

    const getDataFromServer = async (dateRange) => {
        let payload = {
            "date_range_type": "last_365_days",
            "start_date": "01-11-2023",
            "end_date": "20-11-2024"
        }
        if (dateRange && dateRange.id) {
            payload.date_range_type = dateRange.id
        }
        let res = await axios.post('/tasks/arc_central_dashboard', payload)
        setResponseData(res)
    }
    useEffect(() => {
        getDataFromServer()
    }, [])
    useEffect(() => {
        let tempTableData = getDataFromResponse(responseData, user, departments)
        setTableData([...tempTableData])
    }, [responseData])

    const DeptTableID = 'arc-dept-table'

    return (
        <>
            <CentralTableComponent
                componentID={'arc_department_wise_status'}
                componentStyle={{ minWidth: '50%', maxWidth: '55%' }}
                logoComponent={<ArcLogo className='logo' />}
                className=''
                title='Department-wise Compliance Status'
                topBarField={Field}
                rows={tableData}
                Columns={DeptColumns}
                tableField={Field}
                tableID={DeptTableID}
                tableClassName='arc-stats-tbl'
                onDateChange={getDataFromServer}
                imageSrc={props.imageSrc}
                exportCompWidth={1100}
                rightPadding={160}
                TopHeaderBlack={true}
                isReduceRowHeight={true}
                isNotMaxHeight={true}
            />
        </>
    )
}

const getDataFromResponse = (res, user, departments) => {
    if (res.dept_status_list) {
        let dept_compliance = [];
        const brandname = user.ent_org.brandname;
        // const capitalizedBrandname = brandname.charAt(0).toUpperCase() + brandname.slice(1).toLowerCase();
        let totalRow = { department: brandname, total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, };
        let departmentMap = {};

        for (const key in res.dept_status_list) {
            let correctedKey = key.replace(/\s/g, ""); // Remove spaces from the key

            // let isDepartmentValid = departments.find(item => item.id === correctedKey)
            // if (!isDepartmentValid) {
            //     continue;
            // }

            const normalizedKey = correctedKey.toLowerCase(); // Normalize the department name
            const deptStatus = res.dept_status_list[key];
            const total = parseInt(deptStatus.total, 10) || 0;
            const complete = parseInt(deptStatus.Complete, 10) || 0;
            const pending = parseInt(deptStatus.Pending, 10) || 0;
            const inprogress = parseInt(deptStatus.InProgress, 10) || 0;
            const other = total - (complete + inprogress + pending)

            if (departmentMap[normalizedKey]) {
                // If department already exists, add values to existing department
                departmentMap[normalizedKey].total += total;
                departmentMap[normalizedKey].complete += complete;
                departmentMap[normalizedKey].inprogress += inprogress;
                departmentMap[normalizedKey].pending += pending;
                departmentMap[normalizedKey].other += other;
            } else {
                // If department does not exist, create a new entry
                departmentMap[normalizedKey] = {
                    department: correctedKey,
                    total: total,
                    complete: complete,
                    inprogress: inprogress,
                    pending: pending,
                    other: other,
                };
            }
        }

        // Convert departmentMap values to an array
        dept_compliance = Object.values(departmentMap);

        // Update totalRow based on aggregated values
        totalRow.total = dept_compliance.reduce((sum, department) => sum + department.total, 0);
        totalRow.complete = dept_compliance.reduce((sum, department) => sum + department.complete, 0);
        totalRow.inprogress = dept_compliance.reduce((sum, department) => sum + department.inprogress, 0);
        totalRow.pending = dept_compliance.reduce((sum, department) => sum + department.pending, 0);
        totalRow.other = dept_compliance.reduce((sum, department) => sum + department.other, 0);

        // Sort the array alphabetically based on the department names
        dept_compliance.sort((a, b) => a.department.toLowerCase().localeCompare(b.department.toLowerCase()));

        dept_compliance.unshift(totalRow);
        dept_compliance = dept_compliance.filter(row => {
            return Object.entries(row).some(([key, value]) => (
                key !== 'department' && value !== 0
            ))
        })
        dept_compliance.forEach((row) => {
            Object.keys(row).forEach((columnKey) => {
                if (row[columnKey] === 0 || row[columnKey] === '0') {
                    row[columnKey] = '-'
                }
            })
        })
        return dept_compliance;
    } else {
        return [];
    }
};
