import { useEffect, useState } from "react"
import { AuditorLogo } from "../../../components/Assets"
import { axios, getDateInFormat } from "../../../store"
import { CentralTableComponent } from "../../CentralStatsTable";

const Field = { attribute: 'dateRange', type: 'select' }

const Columns = [
    { label: 'Audit Title', attribute: 'title', headerClassName: 'txt-left', width: '35%', isLabel: true , cellClassName:'ellipses-text'},
    { label: 'Status', attribute: 'status', headerClassName: 'txt-left', width: '13%', isIndicator: true, },
    { label: 'End Date', attribute: 'end_date', headerClassName: '', width: '16%', cellClassName:'no-wrap' },
    { label: 'Controls', attribute: 'controls', headerClassName: '', width: '11%' },
    { label: 'Deviations', attribute: 'deviations', headerClassName: '', width: '11%' },
    { label: 'Score', attribute: 'score', headerClassName: '', width: '11%' },
    { label: 'Risk', attribute: 'risk', headerClassName: 'txt-left', width: '13%', isIndicator: true },
]

export function AuditorStats(props) {
    const [state, setState] = useState([])
 
    const getDataFromServer = async(dateRange) => {
        let payload = {
            "date_range_type": "last_365_days",
            "start_date": "01-11-2023",
            "end_date": "20-11-2024"
        }
        if (dateRange && dateRange.id) {
            payload.date_range_type = dateRange.id
        }
        let res = await axios.post('/centrals/dashboard', payload)
        if (res && res.audit_stat && Array.isArray(res.audit_stat)) {
            res.audit_stat.forEach((row_obj) => {
                row_obj.end_date = getDateInFormat(row_obj.end_date)
                row_obj.status = row_obj.status.charAt(0).toUpperCase() + row_obj.status.slice(1).toLowerCase()
            })
            const transformedData = res.audit_stat
            transformedData.forEach((row)=>{
                Object.keys(row).forEach((columnKey) => {
                    if(row[columnKey] === 0 || row[columnKey] === '0' || row[columnKey] === ''){
                        row[columnKey] = '-'
                    }
                }) 
            })
            setState(transformedData)
        }
    }
    useEffect(() => {
        getDataFromServer()
    }, [])


    return (
        <div className="auditor-table-container">
        <CentralTableComponent
            componentID={'audit_status'}
            componentStyle={{ marginBottom: '50px', marginLeft: '30px', width:'77%'}}
            logoComponent={<AuditorLogo className='logo' />}
            className=''
            title='Audit Status'
            topBarField={Field}
            rows={state}
            Columns={Columns}
            tableField={Field}
            tableID='auditor-table'
            tableClassName='auditor-stats-tbl'
            onDateChange={getDataFromServer}
            imageSrc={props.imageSrc}
            exportCompWidth={1100}
            rightPadding={1}
        />
        </div>
    )
}