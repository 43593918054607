import React, { useState, useEffect } from 'react';
import DownFileIcon from '../../assets/images/guide-downfile-icon.png'
import UpFileIcon from '../../assets/images/guide-upfile-icon.png'
import { GuideSection } from './GuideSection';

const DocumentComponent = ({ guideContent, title, isExpanded, setIsExpanded }) => {
    const toggleExpand = () => {
        setIsExpanded(isExpanded ? null : 'Document');
    };
    return (
        <div>
            <div className="title f7 exo2" onClick={toggleExpand}>
                <div className='icon d-flex v-ctr'>{(isExpanded) ? <img src={UpFileIcon} /> : <img src={DownFileIcon} />}</div>
                <div className="title">{title ? title : 'Document Management'}</div>
            </div>
            <hr className="custom-hr" />
            {(isExpanded) && (
                <div className="description">
                    {guideContent &&
                        guideContent.map((sectionContent) => (
                            <GuideSection key={sectionContent.id} sectionContent={sectionContent} />
                        ))}
                </div>
            )}
        </div>
    );
};

export default DocumentComponent;
