import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import JSZip from 'jszip';
import { getDateInExportFormat } from '../../../store';


const moduleIDs = {
    'Documenter_Documentation_Status': 'documenter_dept_status',
    'Arc_Department_vs_Compliance_Status': 'arc_department_wise_status',
    'Arc_Priority_vs_Compliance_Status': 'arc_priority_wise_status',
    'Arc_Department_vs_Priority_Status': 'arc_department_wise_priority_status',
    'Auditor_Audit_Status': 'audit_status',
    'Educator_Assessment_vs_Status': 'educator_assessment_status',
    'Educator_Department_vs_Status': 'educator_department_status',
    'Investigator_Severity_vs_Incident_Status': 'investigator_severity_status',
    'Investigator_Department_vs_Incident_Status': 'investigator_department_status',
    'Continuer_Continuity_Test_Status': 'continuer_status'
}
const getModuleFromId = (moduleId) => {
    let moduleName;
    Object.keys(moduleIDs).forEach((key) => {
        if (moduleId.includes(moduleIDs[key])) {
            moduleName = key
        }
    })
    return moduleName
}
const generateIDsToExport = (modules) => {
    let idsToExport = []
    for (let module of modules) {
        let id = moduleIDs[module]
        let element = true
        let i = 1
        while (element !== null) {
            element = document.getElementById(`${id}_export_${i}`);
            if (element !== null) {
                idsToExport.push(`${id}_export_${i}`)
            }
            i += 1
        }
    }
    return idsToExport
}
const setEllipsesTextInPng =  () => {
    // await new Promise(resolve => setTimeout(resolve, 100));
    const elements = document.getElementsByClassName('export-ellipsis-text')
    for (let i = 0; i < elements.length; i++) {
        let element = elements[i]
        const originalText = element.textContent;
        if (element.scrollWidth > element.offsetWidth) {
            const isOverflowing = element.scrollLeft > 0 || element.scrollWidth > element.clientWidth;
            if (isOverflowing) {
                const transformedText = element.innerText.slice(0, 32) + '...'
                element.innerText = transformedText
            }

        }
    }
}
const setEllipsesTextInPdf = () => {
    const elements = document.getElementsByClassName('export-ellipsis-text')
    for (let i = 0; i < elements.length; i++) {
        let element = elements[i]
        const originalText = element.textContent;
        if (element.scrollWidth > element.offsetWidth) {
            const isOverflowing = element.scrollLeft > 0 || element.scrollWidth > element.clientWidth;
            if (isOverflowing) {
                const transformedText = element.innerText.slice(0, 32) + '...'
                element.innerText = transformedText
            }

        }
    }
}

export const exportGroupToPDF = async (selectedModules) => {
    let dateRangeLabelValue;
    const currentDate = new Date()
    const exportDate = getDateInExportFormat(currentDate)

    if (selectedModules.includes('Export All')) {
        selectedModules = ['Documenter_Documentation_Status', 'Arc_Department_vs_Compliance_Status', 'Arc_Priority_vs_Compliance_Status', 'Arc_Department_vs_Priority_Status', 'Auditor_Audit_Status', 'Educator_Assessment_vs_Status', 'Educator_Department_vs_Status', 'Investigator_Severity_vs_Incident_Status', 'Investigator_Department_vs_Incident_Status', 'Continuer_Continuity_Test_Status']
    }
    let modules = generateIDsToExport(selectedModules)
    let pdfSaveName = 'Fusion_Stats'
    if (selectedModules.length === 1) {
        let module = modules[0]
        let componentID = module.split('_export_')[0];
        const element = document.getElementById(`${componentID}_export_dateRange`);
        if (element) {
            dateRangeLabelValue = element.innerText;
            dateRangeLabelValue = dateRangeLabelValue.split(' ').join('');
        }
        if (dateRangeLabelValue) {
            pdfSaveName = `Fusion_Stats_${exportDate}_${getModuleFromId(module)}_${dateRangeLabelValue}`
        }
        else {
            pdfSaveName = `Fusion_Stats_${exportDate}_${getModuleFromId(module)}`
        }
    }
    else {
        pdfSaveName = `Fusion_Stats_${exportDate}`;
    }
    let module_components = []
    for (let x of modules) {
        const input = document.getElementById(x);
        if (input != null) {
            input.style.display = 'block'
            module_components.push(input)
        }
    }
    // Show loading message
    const loadingElement = document.getElementById('loader-component')
    loadingElement.style.display = 'block'
    const pdf = new jsPDF("l", "px", "a4");
    for (let i = 0; i < module_components.length; i++) {

        const module_component = module_components[i];
        // Add page break if it's not the last module 
        const isLastPage = (i === (module_components.length - 1))
        try {
            await preparePDFPage(pdf, module_component, isLastPage)
        } catch {
            console.log('Error', module)
        }
    }
    pdf.save(`${pdfSaveName}.pdf`);
    // Hide loading message
    setTimeout(() => {
        loadingElement.style.display = 'none'

    }, 1000)
    //
    for (let module_component of module_components) {
        if (module_component != null) {
            module_component.style.display = 'none'
        }
    }
}

const preparePDFPage = async (pdf, module_component, isLastPage) => {
    const margin = 90;
    let canvas;
    const input = module_component
    await new Promise(resolve => setTimeout(resolve, 100));
    setEllipsesTextInPdf()
    canvas = await html2canvas(input, { scrollY: -window.scrollY, });
    const newCanvas = document.createElement('canvas');
    newCanvas.width = canvas.width + (2 * margin);
    newCanvas.height = canvas.height + (2 * margin);
    const context = newCanvas.getContext('2d');
    context.fillStyle = 'white';
    context.fillRect(0, 0, newCanvas.width, newCanvas.height);
    context.drawImage(canvas, margin, margin);
    const imgData = newCanvas.toDataURL('image/jpeg');
    const ratio = newCanvas.width / newCanvas.height;
    const width = pdf.internal.pageSize.getWidth();
    const height = width / ratio;
    pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
    if (!isLastPage) {
        pdf.addPage();
    }
}
export const exportGroupToPNGInZip = async (selectedModules) => {
    let dateRangeLabelValue;
    const currentDate = new Date()
    const exportDate = getDateInExportFormat(currentDate)
    if (selectedModules.includes('Export All')) {
        selectedModules = ['Documenter_Documentation_Status', 'Arc_Department_vs_Compliance_Status', 'Arc_Priority_vs_Compliance_Status', 'Arc_Department_vs_Priority_Status', 'Auditor_Audit_Status', 'Educator_Assessment_vs_Status', 'Educator_Department_vs_Status', 'Investigator_Severity_vs_Incident_Status', 'Investigator_Department_vs_Incident_Status', 'Continuer_Continuity_Test_Status']
    }
    let modules = generateIDsToExport(selectedModules)
    let moduleName = ''
    let zipName = `Fusion_Stats_${exportDate}`;
    if (selectedModules.length === 1) {
        let module = modules[0]
        moduleName = getModuleFromId(module)
        let componentID = module.split('_export_')[0];
        const element = document.getElementById(`${componentID}_export_dateRange`);
        if (element) {
            dateRangeLabelValue = element.innerText;
            dateRangeLabelValue = dateRangeLabelValue.split(' ').join('');
        }
        zipName = `Fusion_Stats_${exportDate}_${moduleName}_${dateRangeLabelValue}`
    }
    // Show loading message
    const loadingElement = document.getElementById('loader-component')
    loadingElement.style.display = 'block'
    if (modules.length === 1) {
        // Download individual PNG files if there is only one module
        let module = modules[0]
        let componentID = module.split('_export_')[0];
        const element = document.getElementById(`${componentID}_export_dateRange`);

        if (element) {
            dateRangeLabelValue = element.innerText;
            dateRangeLabelValue = dateRangeLabelValue.split(' ').join('');
        }
        let canvas;
        const input = document.getElementById(module);
        let originalDisplay = input.style.display
        input.style.display = 'block'
        await new Promise(resolve => setTimeout(resolve, 100));
        setEllipsesTextInPng()
        canvas = await html2canvas(input, { scrollY: -window.scrollY, letterRendering: 1, allowTaint: true, useCORS: true });
        const margin = 80;
        const newCanvas = document.createElement('canvas');
        newCanvas.width = canvas.width + (2 * margin);
        newCanvas.height = canvas.height;

        const context = newCanvas.getContext('2d');
        context.fillStyle = 'white';
        context.fillRect(0, 0, newCanvas.width, newCanvas.height);

        // Draw the image without adding margin at the bottom
        context.drawImage(canvas, margin, margin, canvas.width, canvas.height - margin);
        const imgData = newCanvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        if (dateRangeLabelValue) {
            link.download = `Fusion_Stats_${exportDate}_${moduleName}_0${module.slice(-1)}_${dateRangeLabelValue}.png`
        }
        else {
            link.download = `Fusion_Stats_${exportDate}_${moduleName}_0${module.slice(-1)}.png`;
        }
        link.click();
        input.style.display = originalDisplay
    }
    else {
        const zip = new JSZip();

        for (const module of modules) {
            let componentID = module.split('_export_')[0];
            const element = document.getElementById(`${componentID}_export_dateRange`);
            if (element) {
                dateRangeLabelValue = element.innerText;
                dateRangeLabelValue = dateRangeLabelValue.split(' ').join('');
            }
            if (dateRangeLabelValue) {
                moduleName = `Fusion_Stats_${exportDate}_${getModuleFromId(module)}_0${(module.slice(-1))}_${dateRangeLabelValue}`
            }
            else {
                moduleName = `Fusion_Stats_${exportDate}_${getModuleFromId(module)}_0${(module.slice(-1))}`
            }
            let canvas;
            const input = document.getElementById(module);
            let originalDisplay = input.style.display
            input.style.display = 'block'
            await new Promise(resolve => setTimeout(resolve, 100));
            setEllipsesTextInPng()
            canvas = await html2canvas(input, { scrollY: -window.scrollY, letterRendering: 1, allowTaint: true, useCORS: true });
            const margin = 80;
            const newCanvas = document.createElement('canvas');
            newCanvas.width = canvas.width + (2 * margin);
            newCanvas.height = canvas.height;
            const context = newCanvas.getContext('2d');
            context.fillStyle = 'white';
            context.fillRect(0, 0, newCanvas.width, newCanvas.height);
            context.drawImage(canvas, margin, margin, canvas.width, canvas.height - margin);
            const imgData = newCanvas.toDataURL('image/png');
            input.style.display = originalDisplay
            zip.file(`${moduleName}.png`, imgData.split('base64,')[1], { base64: true });
        }

        zip.generateAsync({ type: 'blob' }).then((content) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = `${zipName}.zip`;
            link.click();
        });
    }
    // Hide loading message
    setTimeout(() => {
        loadingElement.style.display = 'none'

    }, 1000)
    //
};
