import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { FormDialog } from "../../components";


export const VideoPlayer = ({ url })=> {
    const [play, setPlay] = useState(false);

    const handlePlay = () => {
        setPlay(true);
    };

    return (
        <div onClick={handlePlay} style={{ cursor: 'pointer', marginTop:'20px', marginBottom: '20px' }}>
            <ReactPlayer
                url={url}
                playing={play}
                controls={true} 
                width="500px"
                height="250px"
                style={{border: '0.8px solid black'}}
            />
        </div>
    );
}
export const ImageViewer = (props) => {
    const { image_url } = props
    return (
        <FormDialog
            className='learning-template'
            onClose={props.onClose}
        >
            <div className='col v-ctr oy-auto'>
                <img src={image_url} alt="Guide Image" style={{ width: '100%', height:'100%' }} />
            </div>
        </FormDialog>
    )
}
