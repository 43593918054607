import { NeumetricLogo, FusionLogo, } from "../../components";
import './exportPage.scss'

export function ExportPage(props) {
    const compWidth = props.compWidth
    const compHeight = props.compHeight
    return (
        <div id={props.componentID} style={{ position: 'relative', height: compHeight, width: compWidth,display:'none' }}>
            <div className="export-page-container">
                <div className="export-top-left-logo">{props.logoComponent}</div>
                <div className="export-top-right-logo"> {props.imageSrc && <img src={props.imageSrc} alt='logo2' crossOrigin="anonymous" className="logo" />}</div>
                <div className="export-bottom-left-logo"> <FusionLogo className='logo' /></div>
                <div className="export-bottom-right-logo"> <NeumetricLogo className='small_logo' /></div>
                <div className="reg f9 export-bottom-page-no" style={{ width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'end' }}>- Page {(props.index).toString()} of {props.totalPageCount} -</div>

                <div className="export-center-content" style={{ width: props.compWidth, minWidth:props.compWidth,maxWidth:props.compWidth,   height: '100%', marginTop:'-40px', paddingRight:props.rightPadding }}>
                      {props.children}
                </div>

            </div>
        </div>

    )
}