import React, { useState } from 'react';
// import { DownSquareOutlined } from '@ant-design/icons';
// import { UpSquareOutlined } from '@ant-design/icons'
import { GuideSection } from './GuideSection';
import DownFileIcon from '../../assets/images/guide-downfile-icon.png'
import UpFileIcon from '../../assets/images/guide-upfile-icon.png'

const AuditComponent = ({ guideContent, title, isExpanded, setIsExpanded }) => {
  const toggleExpand = () => {
    setIsExpanded(isExpanded ? null : 'Audit');
  };
  return (
    <div>
      <div className="title f7 exo2" onClick={toggleExpand}>
        <div className='icon d-flex v-ctr'>{(isExpanded) ? <img src={UpFileIcon}/> : <img src={DownFileIcon}/>}</div>
        <div className="title">{title ? title : 'Audit Management'}</div>
      </div>
      <hr className="custom-hr" />
      {(isExpanded) && (
        <div className="description">
          {guideContent &&
            guideContent.map((sectionContent) => (
              <GuideSection key={sectionContent.id} sectionContent={sectionContent} />
            ))}
        </div>
      )}
    </div>
  );
};
export default AuditComponent;
