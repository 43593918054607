import React, { useEffect, useState } from "react";
import { axios } from "../../../store";
import { InvestigatorLogo } from "../../../components/Assets"
import { CentralTableComponent } from "../../CentralStatsTable";

export const InvestigatorSeverityStats = (props) => {
    const { user, Field, Columns, severities } = props
    const [responseData, setResponseData] = useState({})
    const [tableData, setTableData] = useState([])
    //
    const getDataFromServer = async (dateRange) => {
        let payload = {
            "date_range_type": "last_365_days",
            "start_date": "01-11-2023",
            "end_date": "20-11-2024"
        }
        if (dateRange && dateRange.id) {
            payload.date_range_type = dateRange.id
        }
        let res = await axios.post('/incidents/severity_dashboard', payload)
        setResponseData(res)
    }
    useEffect(() => {
        getDataFromServer()
    }, [])
    useEffect(() => {
        let tempTableData = getDataFromResponse(responseData, user, severities)
        if (tempTableData) {
            setTableData([...tempTableData])
        }
    }, [responseData])

    return (

        <>
            <CentralTableComponent
                componentID={'investigator_severity_status'}
                componentStyle={{ maxWidth: '55%', }}
                logoComponent={<InvestigatorLogo className='logo' />}
                className=''
                title='Severity-wise Incident Status'
                topBarField={Field}
                rows={tableData}
                Columns={Columns}
                tableField={Field}
                tableID='severity-incident-table'
                tableClassName='investigator-stats-tbl'
                onDateChange={getDataFromServer}
                imageSrc={props.imageSrc}
                exportCompWidth={1100}
                rightPadding={145}
                TopHeaderBlack={true}
                isNotMaxHeight={true}
            />
        </>
    )
}
const getDataFromResponse = (res, user, severities) => {
    if (res) {
        const severity_status = res.severity_status
        // const severities = ['Emergency', 'Critical', 'High', 'Medium', 'Low', 'Minimal']
        const brandname = user.ent_org.brandname;
        let severityStats = []
        let finalTransformedRows = []
        let totalRow = { 'label': brandname, 'max_aging': 0, 'avg_aging': 0, 'total': 0, 'closed': 0, 'inprogress': 0, 'open': 0, 'invalid': 0 }
        let totalMaxAge = 0
        let totalAvgAge = 0
        if (Array.isArray(severities)) {
            for (let i = 0; i < severities.length; i++) {
                const severity = severities[i]['id'];
                const severityRow = severity_status && severity_status[severity];
                const severityStatus = severityRow && severityRow['statuses']
                let row = {
                    label: severity,
                    avg_aging: (severityRow && severityRow.avg_aging) || 0,
                    max_aging: (severityRow && severityRow.max_aging) || 0,
                    total: (severityStatus && severityStatus.total) || 0,
                    closed: (severityStatus && severityStatus.closed) || 0,
                    inprogress: (severityStatus && severityStatus.inprogress) || 0,
                    open: (severityStatus && severityStatus.open) || 0,
                    invalid: (severityStatus && severityStatus.invalid) || 0,
                    labelClassName: `bg${severity}`
                };
                severityStats.push(row);
                totalMaxAge = Math.max(totalMaxAge, row.max_aging)
                totalAvgAge += row.avg_aging
                Object.keys(totalRow).forEach((key) => {
                    if (key !== 'label' && 'max_aging' && 'avg_aging') {
                        totalRow[key] += row[key]
                    }
                })
            }
        };
        const nonZeroSeverityStats = filterNonZeroRows(severityStats)
        let dividend_for_avg_aging = nonZeroSeverityStats.length
        totalRow.max_aging = totalMaxAge
        if (dividend_for_avg_aging < 1) {
            dividend_for_avg_aging = 1
        }
        totalAvgAge = parseInt(totalAvgAge / (dividend_for_avg_aging))
        totalRow.avg_aging = totalAvgAge
        const finalRows = [totalRow, ...severityStats]
        const filteredNonZeroRows = filterNonZeroRows(finalRows)
        finalTransformedRows = convertZeroToHyphen(filteredNonZeroRows)
        return finalTransformedRows
    }
}

function filterNonZeroRows(rows) {
    return rows.filter((row) => {
        for (let key in row) {
            if (['max_aging', 'avg_aging', 'total', 'closed', 'inprogress', 'open', 'invalid'].includes(key) && row[key] !== 0 && row[key] !== NaN && row[key] !== '0') {
                return true
            }
        }
        return false
    })
}
function convertZeroToHyphen(rows) {
    rows.forEach((row) => {
        Object.keys(row).forEach((columnKey) => {
            if ((row[columnKey] === 0) || (row[columnKey] === '0')) {
                row[columnKey] = '-'
            }
        })
    })
    return rows
}

