import React from "react";
import { DocumentorIcon, ContinuerIcon, AuditorIcon, ArcIcon, EducatorIcon, InvestigatorIcon, FusionIcon } from "../../components/Assets";
// import { MenuOutlined } from "@ant-design/icons";
import { MenuOutlined } from "@mui/icons-material";
import { Tooltip } from 'antd';
import { Link } from "react-router-dom";
import { tooltipClasses } from "@mui/material";

const GuideNavItems = [
    // { label: 'Guide', Component: MenuOutlined, },
    { label: 'Document', Component: DocumentorIcon, },
    { label: 'Audit', Component: AuditorIcon, },
    { label: 'Risk', Component: AuditorIcon,  },
    { label: 'Compliance', Component: ArcIcon ,},
    { label: 'Education', Component: EducatorIcon,  },
    { label: 'Incident', Component: InvestigatorIcon,},
    { label: 'Continuity', Component: ContinuerIcon,},
    { label: 'Records', Component: FusionIcon, },
]
export const GuideSideNavBar = (props) => {
  const { handleModuleClick } = props
    return (
        <div className='col h-100 guide-nav-switch'>
            <Tooltip key='Guide'  placement="right">
                <Link to="#" className='mod'
                onClick={() => {
                  handleModuleClick('Guide')
                }}>
                  <MenuOutlined className='icon icon-guide' /><div className="label-guide exo2">Guide</div>
                </Link>
              </Tooltip>
        {
          GuideNavItems.map(({ Component, label }) => {
            return (
              <Tooltip key={label}  placement="right">
                <Link to="#" className='mod'
                onClick={() => {
                  handleModuleClick(label)
                }}
                >
                  <Component className='icon' /><div className="label exo2">{label}</div>
                </Link>
              </Tooltip>
            )
          })
        }
      </div>
    )
}