import React, { useState } from 'react';
import { Select, Space, Checkbox } from 'antd';

const App = ({ onChange }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const options = [
        // { label: 'Export All', value: 'export_all' },
        {
            label: 'Documenter',
            options: [
                { label: 'Documentation Status', value: 'Documenter_Documentation_Status' },
            ]
        },
        {
            label: 'Arc',
            options: [
                { label: 'Department-wise Compliance Status', value: 'Arc_Department_vs_Compliance_Status' },
                { label: 'Priority-wise Compliance Status', value: 'Arc_Priority_vs_Compliance_Status' },
                { label: 'Department-wise Priority Status', value: 'Arc_Department_vs_Priority_Status' },
            ],
        },
        {
            label: 'Auditor',
            options: [{ label: 'Audit Status', value: 'Auditor_Audit_Status' }]
        },
        {
            label: 'Educator',
            options: [
                { label: 'Assessment-wise Education Status', value: 'Educator_Assessment_vs_Status' },
                { label: 'Department-wise Education Status', value: 'Educator_Department_vs_Status' },
            ],
        },
        {
            label: 'Investigator',
            options: [
                { label: 'Severity-wise Incident Status', value: 'Investigator_Severity_vs_Incident_Status' },
                { label: 'Department-wise Incident Status', value: 'Investigator_Department_vs_Incident_Status' },
            ],
        },
        {
            label: 'Continuer',
            options: [{ label: 'Continuer Status', value: 'Continuer_Continuity_Test_Status' }]
        },
    ];

    const handleChange = (values) => {
        setSelectedOptions(values);
        onChange(values);
    };

    const CustomOption = ({ option }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
                value={option.value}
                checked={selectedOptions.includes(option.value)}
                onChange={(e) => handleChange(e.target.checked ? [...selectedOptions, option.value] : selectedOptions.filter(item => (item !== option.value && item !== 'export_all')))}
                style={{ marginRight: '8px' }}
            />
            <span>{option.label}</span>
        </div>
    );
    const CustomExportAllOption = ({ option }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
                value={option.value}
                checked={selectedOptions.includes(option.value)}
                onChange={(e) => {
                    let temp = []
                    if (e.target.checked) {
                        options.forEach((option_group) => {
                            option_group.options.forEach((option_obj) => {
                                temp.push(option_obj.value)
                            })
                        })
                        temp.push(option.value)
                    }
                    else {
                        temp = selectedOptions.filter(item => item !== option.value)
                    }

                    handleChange(temp)
                }
                }
                style={{ marginRight: '8px' }}
            />
            <span>{option.label}</span>
        </div>
    );
    const isExportAllSelected = selectedOptions.includes('Export All');
    const customStyles = { multiValueRemove: (base) => ({ ...base, display: 'none' }) }
    return (
        <Space wrap className=''>
            <div className='f9 exo2' style={{ width: '70px' }}>Module: <span style={{ color: 'red' }}>*</span></div>
            <Select
                mode="multiple"
                placeholder='select'
                style={{
                    width: 300,customStyles
                }}
                className='custom-border'
                value={selectedOptions}
                showSearch={false}
                notFoundContent={null}
                suffixIcon={(selectedOptions.length >0)? null : undefined}
                onDeselect={(value) => handleChange(selectedOptions.filter(item => item !== value))}

            >
                {
                    !isExportAllSelected &&
                        <>
                            <option>
                                <CustomExportAllOption option={{ label: 'Export All', value: 'Export All' }} />
                            </option>
                            {options.map(group => (
                                <>
                                    <option>
                                        {group.label}
                                    </option>
                                    {
                                        group.options && group.options.map((option) => {
                                            return <option><CustomOption option={option} /></option>
                                        })
                                    }
                                </>
                            ))}
                        </>
                }

            </Select>
        </Space>
    );
};

export default App;
