import { useEffect, useState } from "react"
import { EducatorLogo } from "../../../components/Assets"
import { CentralTableComponent } from "../../CentralStatsTable";
import { axios } from "../../../store"

const Field = { attribute: 'dateRange', type: 'select' }

const AssessmentWiseCoverageColumns = [
    { attribute: 'assessments', label: 'Assessments', width: '25%', headerClassName: 'txt-left', cellClassName: 'ellipses-text ', isLabel: true },
    { attribute: 'coverage', label: 'Coverage (%)', headerClassName: 'bgDDFFFF', width: '10%' },
    { attribute: 'total', label: 'Total', headerClassName: 'bgDDFFFF', width: '10%' },
    { attribute: 'cleared', label: 'Cleared', headerClassName: 'published', width: '10%' },
    { attribute: 'pending', label: 'Pending', headerClassName: 'cFE3333', width: '10%' },
];
const DepartmentWiseCoverageColumns = [
    { attribute: 'dept', label: '', headerClassName: 'txt-left', width: '10%', isLabel: true},
    { attribute: 'coverage', label: 'Coverage (%)', headerClassName: 'bgDDFFFF', width: '9%' },
    { attribute: 'total', label: 'Total', headerClassName: 'bgDDFFFF', width: '9%' },
    { attribute: 'cleared', label: 'Cleared', headerClassName: 'published', width: '7%' },
    { attribute: 'pending', label: 'Pending', headerClassName: 'cFE3333', width: '7%' },
];

export function EducatorStats({ user, imageSrc }) {
    const brandName = user.ent_org.brandname
    // const capitalizedBrandname = brandName.charAt(0).toUpperCase() + brandName.slice(1).toLowerCase();
    const [assessmentRows, setAssessmentRows] = useState([])
    const [departmentRows, setDepartmentRows] = useState([])

    const getAssessmentData = async (dateRange) => {
        let payload = {
            "date_range_type": "last_365_days",
            "start_date": "01-11-2023",
            "end_date": "20-11-2024"
        }
        if (dateRange && dateRange.id) {
            payload.date_range_type = dateRange.id
        }
        let res = await axios.post('/educators/central_overall_coverage_dashboard/?page=1&per_page=100', payload)

        let output=[]
        let totalRow = {
            "assessments": brandName,
            "coverage": 0,
            "total": 0,
            "cleared": 0,
            "pending": 0
        }
        // let res = await axios.get('/educators/central_overall_coverage_dashboard/?page=1&per_page=100')
        if (res && res.overall_coverage && Array.isArray(res.overall_coverage)) {
            if(res.overall_coverage.length>0){
                output = res.overall_coverage.map((row_array) => {
                    let row_obj = {
                        assessments: row_array[0] || 0,
                        coverage: row_array[1].coverage || 0,
                        total: row_array[1].total || 0,
                        cleared: row_array[1].cleared || 0,
                        pending: row_array[1].pending || 0,
                    }
    
                    return row_obj
                })
                output.forEach((assessment_obj) => {
                    totalRow.total += parseInt(assessment_obj.total);
                    totalRow.cleared += parseInt(assessment_obj.cleared);
                    totalRow.pending += parseInt(assessment_obj.pending);
                })
        
                let totalCoverage = (Math.ceil(totalRow.cleared / totalRow.pending))
                totalRow.coverage = `${totalCoverage}%`
                output.unshift(totalRow)
                output.forEach((row) => {
                    Object.keys(row).forEach((columnKey) => {
                        if (row[columnKey] === 0 || row[columnKey] === '0') {
                            row[columnKey] = '-'
                        }
                    })
                })
            }
            }

        setAssessmentRows(output)

    }
    const getDepartmentData = async (dateRange) => {
        let payload = {
            "date_range_type": "last_365_days",
            "start_date": "01-11-2023",
            "end_date": "20-11-2024"
        }
        if (dateRange && dateRange.id) {
            payload.date_range_type = dateRange.id
        }
        let res = await axios.post('/educators/central_department_converage_dashboard/?page=1&per_page=100', payload)
        // let res = await axios.get('/educators/central_department_converage_dashboard/?page=1&per_page=100')
        if (res && res.department_coverage && Array.isArray(res.department_coverage)) {
            let responseData = res.department_coverage
            responseData.sort((a, b) => a.dept.toLowerCase().localeCompare(b.dept.toLowerCase()));
            let totalRow = {
                "dept": brandName,
                "coverage": 0,
                "total": 0,
                "cleared": 0,
                "pending": 0
            }
            if(res.department_coverage.length>0){
                responseData.forEach(item => {
                    totalRow.total += (item.total || 0);
                    totalRow.cleared += (item.cleared || 0);
                    totalRow.pending += (item.pending || 0);
                })
                let totalCoverage = (Math.ceil(totalRow.cleared / totalRow.pending))
                totalRow.coverage = `${totalCoverage}%`
                responseData.unshift(totalRow)
                responseData = responseData.filter(row => {
                    return Object.entries(row).some(([key, value]) => ((key !== 'department') && (value !== 0) && (value !== '0%')));
                });
                responseData.forEach((row) => {
                    Object.keys(row).forEach((columnKey) => {
                        if ((row[columnKey] === 0) || (row[columnKey] === '0')) {
                            row[columnKey] = '-'
                        }
                    })
                })
            }
           
            setDepartmentRows(responseData)
        }
    }
    useEffect(() => {
        getAssessmentData()
        getDepartmentData()
    }, [])

    return (
        <>
            <CentralTableComponent
                componentID={'educator_assessment_status'}
                componentStyle={{ minWidth: '50%', maxWidth: '60%', }}
                logoComponent={<EducatorLogo className='logo' />}
                className=''
                title='Assessment-wise Status'
                topBarField={Field}
                rows={assessmentRows}
                Columns={AssessmentWiseCoverageColumns}
                tableField={Field}
                tableID='education-assmnt-table'
                tableClassName='edu-dept-stats-tbl'
                onDateChange={getAssessmentData}
                imageSrc={imageSrc}
                exportCompWidth={1100}
                rightPadding={80}
                TopHeaderBlack={true}
            />
            <CentralTableComponent
                componentID={'educator_department_status'}
                componentStyle={{ maxWidth: '50%', }}
                logoComponent={<EducatorLogo className='logo' />}
                className=''
                title='Department-wise Status'
                topBarField={Field}
                rows={departmentRows}
                Columns={DepartmentWiseCoverageColumns}
                tableField={Field}
                tableID='education-dept-table'
                tableClassName='edu-dept-stats-tbl'
                onDateChange={getDepartmentData}
                imageSrc={imageSrc}
                exportCompWidth={1100}
                rightPadding={250}
                TopHeaderBlack={true}
                isNotMaxHeight={true}
                isReduceRowHeight={true}
            />
        </>
    )
}
