import React, { useState } from 'react';
import { CentralLogo, ProfilePic, CompanyLogo, DocumentorIcon, ContinuerIcon, AuditorIcon, ArcIcon, ConsoleIcon, ControllerIcon, EducatorIcon, InvestigatorIcon, CentralIcon, FusionIcon } from "../Assets";
import { useNavigate, Link } from "react-router-dom";
import { Tooltip, Tabs, Dropdown, } from 'antd';
import { usePub } from "../Event";
import "./style.scss";

const DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;
const SUFFIX = process.env.REACT_APP_DOMAIN_SUFFIX || '';

const Modules = [
  { moduleId: 'auditor', Component: AuditorIcon, label: 'Auditor' },
  { moduleId: 'arc', Component: ArcIcon, label: 'Arc' },
  { moduleId: 'console', Component: ConsoleIcon, label: 'Console' },
  { moduleId: 'controller', Component: ControllerIcon, label: 'Controller', isAdmin: true },
  { moduleId: 'educator', Component: EducatorIcon, label: 'Educator' },
  { moduleId: 'investigator', Component: InvestigatorIcon, label: 'Investigator' },
  { moduleId: 'continuer', Component: ContinuerIcon, label: 'Continuer' },
  { moduleId: 'documenter', Component: DocumentorIcon, label: 'Documenter' },

]
const getModuleLink = (moduleId, user) => {
  const sessionToken = user.session_token;
  return `https://${moduleId}-sandbox.${DOMAIN}/login?sessionToken=${sessionToken}`;
}
export const NavBar = (props) => {
  const { user, isAdmin, isArchitect } = props
  const publish = usePub();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = (e) => {
    e.preventDefault();
    publish('SESSION_LOGOUT');
  }
  const getSubscribedModules = () => {
    let modules = Modules.filter((_) => {
      if (_.isAdmin) {
        return (isAdmin || isArchitect)
      }
      return _.moduleId !== module
    })
    modules.sort((modA, modB) => {
      let moduleIdA = modA.moduleId, moduleIdB = modB.moduleId
      if (moduleIdA < moduleIdB) {
        return -1;
      }
      if (moduleIdA > moduleIdB) {
        return 1;
      }
      return 0;
    })
    return modules
  }

  const getTab = () => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    return currentUrlParams.get('tab')
    // return currentUrlParams.get('tab') || 'Support'
  }
  const [state, setState] = useState({ tab: getTab() })
  const ProfileDropdown = () => {
    return (
      <div className='col documenter-menus'>
        <div className='btn w-100 row btn-profile v-start no-border'>
          <ProfilePic className='profile-img' />
          <div className='col f-rest'>
            <div className='col w-100'>
              <span className='f7 reg usename'>{user.firstname} {user.lastname}</span>
              <span className='f9 reg c1155CC email'>{user.email}</span>
            </div>
            <div className='row h-btn profile-signout w-100'>
              <Link to={getModuleLink('console', user) + '&redirect=profile'} target='_blank' className='f9 profile' >Profile</Link>
              <Link className='f9 logout' onClick={handleLogout}>Sign Out</Link>
            </div>
          </div>
        </div>
        {
          getSubscribedModules().map(({ label, Component, moduleId }) => {
            return (
              <a className='row v-ctr f8 menu-entry link c00085' rel="noreferrer" key={moduleId} href={getModuleLink(moduleId, user)}>
                <Component className="mod-logo" />
                <span>{label}</span>
              </a>
            )
          })
        }
      </div>
    )
  }
  return (
    <nav className='row w-100 nav-bar h-btn'>
      <div className='row h-100' >
        <CentralLogo className='logo' onClick={() => navigate('/')} />
        <div className='d-flex v-ctr' style={{ paddingLeft: '100px' }}>
          <Tabs
            activeKey={state.tab}
            onChange={(key) => {
              setState((_) => ({ ..._, tab: key }));
              navigate(`/C/${key}`);
            }}
          >
            <Tabs.TabPane
              tab={<div style={{ fontSize: '16px', paddingTop: '18px' }}>Stats</div>}
              key="stats"
            >
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<div style={{ fontSize: '16px', paddingTop: '18px' }}>Guide</div>}
              key="guide"
            >
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
      <div className='row'>
        <Dropdown
          overlayClassName="menu-overlay"
          dropdownRender={ProfileDropdown} placement="bottomRight" trigger={['click']} arrow>
          <div className='btn row btn-profile no-border'>
            <ProfilePic className='profile-img' />
            {/* <span className='f8 reg'>{user.firstname} {user.lastname}</span> */}
            <span className='f12'>
              <i className='c00085 icon-caret-down icon' />
            </span>
          </div>
        </Dropdown>
        <CompanyLogo user={user} />
      </div>
    </nav>
  )
}

const useNavigateBack = () => {
  const navigate = useNavigate();
  const navigateBack = () => {
    if (window.history && window.history.state) {
      navigate(-1)
    } else {
      navigate('/')
    }
  }
  return navigateBack
}

const CentralModules = [

  { moduleId: 'fusion', Component: FusionIcon, tooltip: 'Fusion', },
  { moduleId: 'documenter', Component: DocumentorIcon, tooltip: 'Documenter', },
  { moduleId: 'arc', Component: ArcIcon, tooltip: 'Arc' },
  { moduleId: 'auditor', Component: AuditorIcon, tooltip: 'Auditor', },
  { moduleId: 'investigator', Component: InvestigatorIcon, tooltip: 'Investigator', },
  { moduleId: 'educator', Component: EducatorIcon, tooltip: 'Educator', },
  { moduleId: 'continuer', Component: ContinuerIcon, tooltip: 'Continuer', },
]

export const SideNavBar = (props) => {
  const { setModuleClicked, moduleClicked } = props
  return (
    <div className='col h-100 nav-module-switch'>
      {
        CentralModules.map(({ Component, moduleId, tooltip, }) => {
          return (
            <Tooltip key={moduleId} title={tooltip} placement="right">
              <Link to="#" className='mod'
                onClick={() => {
                  setModuleClicked(moduleId)
                }
                } >
                <Component className='icon' />
              </Link>
            </Tooltip>
          )
        })
      }
    </div>
  )
}
