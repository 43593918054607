import { getDateInFormat } from "../../store"
import React, { useEffect } from "react";
import { Select, Space } from "antd";

export function CentralTableTopBar(props) {
    const { Field, title, handleFieldChange, tableID, logoComponent, options, selectedDateId, selectedDateLabel } = props;
    const [tableWidth, setTableWidth] = React.useState(600)

    useEffect(() => {
        const updateTableWidth = () => {
            const table = document.getElementById(tableID);
            if (table) {
                const width = table.offsetWidth;
                setTableWidth(width);
            }
        };
        updateTableWidth();
        window.addEventListener('resize', updateTableWidth);
        return () => {
            window.removeEventListener('resize', updateTableWidth);
        };
    }, [props.rows]);

    return (
        <div>
            {!props.isExport && <div style={{ marginBottom: '12px' }}> {logoComponent} </div>}
            <div className="d-flex" style={{ width: props.tableWidth ? props.tableWidth : tableWidth }}>
                <div className="f4 exo2 bold c238787 title flex-grow-1" style={{ marginBottom: '12px' }} >
                    <div className="exo2">{title}</div>
                </div>
                <div className="" >
                    {(Field === '')
                        ? <div className="d-flex h-end f10" style={{ marginTop: '25px' }}  >
                            <div className='exo2 f9' style={{ marginRight: '5px' }}>Stats as on </div> <b className="exo2 f9"> {getDateInFormat()}</b>
                        </div>
                        :
                        <>
                            {!(props.isExport) ?
                                <div>
                                    <Space wrap className='mb1'>
                                        <Select
                                            style={{ width: 200 }}
                                            className='f7 custom-border'
                                            onChange={handleFieldChange}
                                            options={options.map((option) => {
                                                return { value: option.id, label: option.label }
                                            })}
                                            value={selectedDateId}
                                        />
                                    </Space>
                                    <div className="d-flex h-end" style={{ marginTop: '-12px' }}  >
                                        <div className='exo2 f9' style={{ marginRight: '5px' }}>Stats as on </div> <b className="exo2 f9"> {getDateInFormat()}</b>
                                    </div>
                                </div>
                                :
                                <div className="d-flex h-end" style={{ marginTop: '22px' }}  >
                                    <div className="d-flex exo2 f9">
                                        <div style={{ marginRight: '5px' }} className="exo2 f9">Stats as on </div>
                                        <div style={{ marginRight: '4px' }} className="exo2 f9"><b style={{ marginRight: '3px' }} className="exo2 f9"> {getDateInFormat()}</b> for </div>
                                        {selectedDateLabel && <div id={`${props.componentId}_dateRange`} className="exo2 f9">{selectedDateLabel}</div>}
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}