import React, { useEffect, useState } from "react";
import { axios } from "../../../store";
import { InvestigatorLogo } from "../../../components/Assets"
import { CentralTableComponent } from "../../CentralStatsTable";

export const InvestigatorDepartmentStats = (props) => {
    const { user, Field, Columns, departments } = props
    const [responseData, setResponseData] = useState({})
    const [tableData, setTableData] = useState([])
    //
    const getDataFromServer = async (dateRange) => {
        let payload = {
            "date_range_type": "last_365_days",
            "start_date": "01-11-2023",
            "end_date": "20-11-2024"
        }
        if (dateRange && dateRange.id) {
            payload.date_range_type = dateRange.id
        }
        let res = await axios.post('/incidents/department_dashboard', payload)
        setResponseData(res)
    }
    useEffect(() => {
        getDataFromServer()
    }, [])
    useEffect(() => {
        let tempTableData = getDataFromResponse(responseData, user, departments)
        if (tempTableData) {
            setTableData([...tempTableData])
        }
    }, [responseData])

    return (

        <>
            <CentralTableComponent
                componentID={'investigator_department_status'}
                componentStyle={{ maxWidth: '60%', }}
                logoComponent={<InvestigatorLogo className='logo' />}
                className=''
                title='Department-wise Incident Status'
                topBarField={Field}
                rows={tableData}
                Columns={Columns}
                tableField={Field}
                tableID='department-incident-table'
                tableClassName='investigator-stats-tbl'
                onDateChange={getDataFromServer}
                imageSrc={props.imageSrc}
                exportCompWidth={1100}
                rightPadding={60}
                TopHeaderBlack={true}
                isReduceRowHeight={true}
                isNotMaxHeight={true}
            />
        </>
    )
}
const getDataFromResponse = (res, user, department_list) => {
    if (res) {
        const brandname = user.ent_org.brandname;
        const department_status = res.department_status
        let departmentStats = []
        let finalTransformedRows = []
        let totalRow = { 'label': brandname, 'max_aging': 0, 'avg_aging': 0, 'total': 0, 'closed': 0, 'inprogress': 0, 'open': 0, 'invalid': 0 }
        let totalMaxAge = 0
        let totalAvgAge = 0

        if (Array.isArray(department_list) && department_status) {
            for (let i = 0; i < department_list.length; i++) {
                const department = department_list[i]['id']
                const departmentRow = department_status && department_status[department]
                const departmentStatus = departmentRow && departmentRow['statuses']
                let row = {
                    label: department,
                    avg_aging: (departmentRow && departmentRow.avg_aging) || 0,
                    max_aging: (departmentRow && departmentRow.max_aging) || 0,
                    total: (departmentStatus && departmentStatus.total) || 0,
                    closed: (departmentStatus && departmentStatus.closed) || 0,
                    inprogress: (departmentStatus && departmentStatus.inprogress) || 0,
                    open: (departmentStatus && departmentStatus.open) || 0,
                    invalid: (departmentStatus && departmentStatus.invalid) || 0,
                }
                departmentStats.push(row);
                totalMaxAge = Math.max(totalMaxAge, row.max_aging)
                totalAvgAge += row.avg_aging
                Object.keys(totalRow).forEach((key) => {
                    if (key !== 'label' && 'max_aging' && 'avg_aging') {
                        totalRow[key] += row[key]
                    }
                })
            }
        }
        const nonZeroDepartmentStats = filterNonZeroRows(departmentStats)
        let dividend_for_avg_aging = nonZeroDepartmentStats.length
        totalRow.max_aging = totalMaxAge
        if (dividend_for_avg_aging < 1) {
            dividend_for_avg_aging = 1
        }
        totalAvgAge = parseInt(totalAvgAge / (dividend_for_avg_aging))
        totalRow.avg_aging = totalAvgAge
        const finalRows = [totalRow, ...departmentStats]
        const filteredNonZeroRows = filterNonZeroRows(finalRows)
        finalTransformedRows = convertZeroToHyphen(filteredNonZeroRows)
        return finalTransformedRows

    }
}

function filterNonZeroRows(rows) {
    return rows.filter((row) => {
        for (let key in row) {
            if (['max_aging', 'avg_aging', 'total', 'closed', 'inprogress', 'open', 'invalid'].includes(key) && row[key] !== 0 && row[key] !== NaN && row[key] !== '0') {
                return true
            }
        }
        return false
    })
}
function convertZeroToHyphen(rows) {
    rows.forEach((row) => {
        Object.keys(row).forEach((columnKey) => {
            if ((row[columnKey] === 0) || (row[columnKey] === '0')) {
                row[columnKey] = '-'
            }
        })
    })
    return rows
}

