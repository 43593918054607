import { InvestigatorSeverityStats } from "./SeverityWiseIncident"
import { InvestigatorDepartmentStats } from "./DepartmentWiseIncident"

export function InvestigatorStats(props) {
    const { user, severities, departments ,imageSrc } = props
    const Field = { attribute: 'dateRange', type: 'select' }

    const Columns = [
        { label: '', attribute: 'label', headerClassName: '', width: '10%', headerClassName: 'status' },
        { label: 'Max Aging <br/>(Days)', attribute: 'max_aging', width: '10%', headerClassName: 'bgDDFFFF no-wrap' },
        { label: 'Avg Aging <br/>(Days)', attribute: 'avg_aging', width: '10%', headerClassName: 'bgDDFFFF no-wrap' },
        { label: '<br/>Total', attribute: 'total', width: '8%', headerClassName: 'bgDDFFFF' },
        { label: '<br/>Closed', attribute: 'closed', width: '8%', headerClassName: 'published closed ' },
        { label: '<br/>InProgress', attribute: 'inprogress', width: '8%', headerClassName: 'status inprogress orange-f77a50' },
        { label: '<br/>Open', attribute: 'open', width: '8%', headerClassName: 'status open cFE3333' },
        { label: '<br/>Invalid', attribute: 'invalid', width: '8%', headerClassName: 'status invalid c777' },
    ]

    return (
        <>
            <InvestigatorSeverityStats Field={Field} Columns={Columns} user={user}severities={severities} imageSrc={imageSrc}/>
            <InvestigatorDepartmentStats Field={Field} Columns={Columns} user={user} departments={departments} imageSrc={imageSrc}/>
        </>
    )
}