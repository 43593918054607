import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../common";

const initialState = {
  departments: [],
  documents: null,
  //
  statistics: { severity: [], department: [] },
  statusTypes: [],
  severityTypes: [],
  dateRangeEnumns: [],
  activeGuides: null,
  showGuide: null,
}
const findDocIndex = (list, id) => {
  return list.findIndex((_) => _.id === id)
}
const docSlice = createSlice({
  name: "document",
  initialState: initialState,
  reducers: {
    fetchDepts: () => { },
    fetchDocTypes: () => { },
    fetchDocStatusList: () => { },
    fetchCentralGuides: () => { },
    fetchCentralGuide: () => { },

    setCurrentAPI: (state, action) => {
      state.currentAPI = action.payload
    },
    setDepts: (state, action) => {
      state.departments = action.payload
    },
    setDocTypes: (state, action) => {
      state.docTypes = action.payload
    },
    setDocStatus: (state, action) => {
      state.docStatusList = action.payload
    },
    fetchDocuments: () => { },
    setDocuments: (state, action) => {
      const { list, archives } = action.payload
      if (list) {
        state.documents = list
      }
      if (archives) {
        state.archives = archives
      }
    },

    fetchDocStats: () => { },
    setDocStats: (state, action) => {
      state.stats = action.payload
    },
    //
    fetchDeptStats: () => { },
    fetchSeverityStats: () => { },
    fetchStatusTypes: () => { },
    setStatusTypes: (state, action) => {
      state.statusTypes = action.payload
    },
    fetchDateRangeEnums: () => { },
    setDateRangeEnums: (state, action) => {
      state.dateRangeEnumns = action.payload
    },
    fetchSeverityTypes: () => { },
    setSeverityTypes: (state, action) => {
      state.severityTypes = action.payload
    },
    setGuides: (state, action) => {
      state.activeGuides = action.payload
    },
    setShowGuide: (state, action) => {
      const { guide } = action.payload;
      state.showGuide = guide
    },
  }
})
export const docActions = docSlice.actions;

ReducerRegistry.register("document", docSlice.reducer)