import DeptWiseCompliance from "./DeptWiseComp";
import PriorityWiseCompliance from "./PriorityWiseComp";
import DeptWiseSeverity from "./DeptWiseSeverity";

export function ArcStats(props) {
    const { user, statusList, departments, priorities } = props

    return (
        <>
        <DeptWiseCompliance 
            {...props}
        />
        <PriorityWiseCompliance 
            {...props}
        />
        <DeptWiseSeverity
            {...props}
        />
        </>
    )
}
